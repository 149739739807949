import React from "react";
import {
    ChipField,
    Datagrid,
    DateField,
    DateInput,
    EditButton,
    Filter,
    NumberInput,
    ReferenceField,
    ShowButton,
    TextField,
    TextInput
} from "react-admin";
import {DepaActionColStyles, DepaTooltip, List} from "../../../DepaComponents/Components";
import {Fade} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";

/**
 * Input Filter for ServiceBills
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BillFilters = (props) => (
    <Filter {...props}>
        <TextInput label="resources.service_task.fields.service_contract" source="serviceContract.identifier"/>
        <NumberInput source={'minutes'} label={'resources.service_bills.fields.minutes'}/>
        <DateInput source="billDate" label={'resources.service_bills.fields.bill_date'} fullWidth
                   options={{format: "d MMM yyyy", clearable: true}}
                   providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
    </Filter>
);

/**
 * List View
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BillList = (props) => {
    const classes = DepaActionColStyles()
    return (<List {...props} title="resources.service_bills.title" filters={<BillFilters/>}>
            <Datagrid
                rowClick={'edit'}
                optimized
            >
                <ReferenceField reference={'service_contracts'} source={'serviceContract'}
                                label={'resources.service_bills.fields.service_contract'}>
                    <ChipField source={'identifier'} optiontext={'identifier'}/>
                </ReferenceField>
                <TextField source="minutes" label="resources.service_bills.fields.minutes"/>
                <DateField source="billDate" label={'resources.service_bills.fields.bill_date'}/>
                <DepaTooltip title="Anzeigen" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                             leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                    <ShowButton label={''}/>
                </DepaTooltip>
                <DepaTooltip title="Bearbeiten" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                             leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                    <EditButton label={''}/>
                </DepaTooltip>
            </Datagrid>
        </List>
    );
};

export default BillList;