import * as React from 'react';
import {
    DateField,
    EditButton,
    EmailField,
    FunctionField,
    ListButton,
    ReferenceField,
    ShowButton,
    TextField,
    useTranslate
} from 'react-admin';
import NotesIcon from '@material-ui/icons/Notes';
import {Box, Divider, Typography} from '@material-ui/core';

export const ContractAside = ({record, link}) => {
    const translate = useTranslate();
    return <Box ml={4} width={300} minWidth={300}>
        <Box textAlign="center" mb={2}>
            {link === 'show' ? (
                <>
                    <ListButton/>
                    <EditButton
                        basePath="/service_contracts"
                        record={record}
                        label="Vertrag"
                    />
                </>
            ) : (
                <>
                    <ListButton/>
                    <ShowButton
                        basePath="/service_contracts"
                        record={record}
                        icon={<NotesIcon/>}
                        label="Arbeitsnachweise"
                    />
                </>
            )}
        </Box>

        {/* ServiceContract Infos */}
        <Typography variant="subtitle2">{record?.identifier}</Typography>
        <Divider/>
        <Box mb={1}>
            <Typography variant="body2" color="textSecondary" component="span">
                {translate('resources.contracts.fields.service_contract_type')}:
            </Typography> {' '}
            <TextField source="serviceContractType.identifier"/>
            <Typography variant="body2" color="textSecondary" component="span">
                <br/>
                {translate('resources.contracts.fields.date_start')}:
            </Typography> {' '}
            <DateField
                source="dateStart"
                options={{year: 'numeric', month: 'long', day: 'numeric'}}
                color="textPrimary"
            />
        </Box>

        <Box mb={3}>
            <Typography variant="body2">
            </Typography>
        </Box>


        {/* First ContractRate */}
        <Typography variant="subtitle2">{translate('resources.contracts.fields.contract_rate')}:</Typography>
        <Divider/>

        <Box mt={1} mb={3}>
            <Typography variant="body2" color="textSecondary" component="span">
                Name :
            </Typography> {' '}
            <TextField source="firstContractRate.identifier"/>
            <br/>
            <Typography variant="body2" color="textSecondary" component="span">
                Stundensatz :
            </Typography> {' '}
            <TextField source="firstContractRate.hourlyRate"/>
        </Box>

        {/* Contact */}
        <Typography variant="subtitle2">{translate('resources.contracts.fields.crm_contact')}:</Typography>
        <Divider/>

        <Box mt={1} mb={3}>
            <Typography component="span" variant="body2" color="textSecondary">
                Name:
            </Typography>{' '}
            <ReferenceField
                resource="crm_contacts"
                source="crmContact"
                reference="crm_contacts"
            >
                <FunctionField
                    source="last_name"
                    render={record =>
                        record ? `${record?.firstName} ${record.surName}` : ''
                    }
                />
            </ReferenceField>
            <br/>
            <Typography component="span" variant="body2" color="textSecondary">
                {translate('resources.customers.fields.crm_company')}:
            </Typography>{' '}
            <ReferenceField
                resource="crm_contacts"
                source="crmContact"
                reference="crm_contacts"
            >
                <FunctionField
                    source="last_name"
                    render={record =>
                        record ? `${record?.crmCompany.name}` : ''
                    }
                />
            </ReferenceField>
            <br/>
            <Typography component="span" variant="body2" color="textSecondary">
                {translate('resources.customers.fields.email')}:
            </Typography>{' '}
            <ReferenceField
                resource="crm_contacts"
                source="crmContact"
                reference="crm_contacts"
                link={null}
            >
                <EmailField source={'email'} variant="body2"/>
            </ReferenceField>
            <br/>
            <Typography component="span" variant="body2" color="textSecondary">
                {translate('resources.customers.fields.phone')}:
            </Typography>{' '}
            <ReferenceField
                resource="crm_contacts"
                source="crmContact"
                reference="crm_contacts"
                link={null}
            >
                <TextField source={'phone'} variant="body2"/>
            </ReferenceField>

        </Box>


        {/* ServiceTasks */}
        <Box mb={1}>
            <Typography variant="subtitle2"> {translate('resources.contracts.fields.serviceTasks')}:</Typography>
            <Divider/>
        </Box>
        <Box mt={1}>
            <Typography variant="body2" color="textSecondary" component="span">
                Abrechnungszeitraum:
            </Typography> {' '}
            <DateField source="cycleStartDate"/> {' - '} <DateField source="cycleEndDate"/>
        </Box>
        <Box mt={1}>
            <Typography variant="body2" color="textSecondary" component="span">
                {translate('resources.contracts.fields.hoursPerMonth')}:
            </Typography> {' '}
            <TextField source="hourPerMonth"/> {' '}
            <Typography variant="body2" color="textPrimary" component="span">
                ({record?.hourPerMonth * 60} Minuten)
            < /Typography>
        </Box>
        <Box mt={1}>
            <Typography variant="body2" color="textSecondary" component="span">
                Noch verfügbare Zeit:
            </Typography> {' '}
            <TextField source="availableTime"/> {' '}
            <Typography variant="body2" color="textPrimary" component="span">
                Stunden
            </Typography>
        </Box>
        <Box mt={1}>
            <Typography variant="body2" color="textSecondary" component="span">
                Abgerechnete Zeit:
            </Typography> {' '}
            <TextField source="usedTime"/> {' '}
            <Typography variant="body2" color="textPrimary" component="span">
                Stunden
            </Typography>
        </Box>
    </Box>
};