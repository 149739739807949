//import { default as ServiceContractRateShow } from './show';
import { default as ServiceContractRateCreate } from './create';
import { default as ServiceContractRateList } from './list';
import { default as ServiceContractRateEdit } from './edit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: ServiceContractRateList,
    create: ServiceContractRateCreate,
    edit: ServiceContractRateEdit
    //show: CrmContactShow
};