import React from "react";
import {List} from "../../../DepaComponents/Components";
import {TextField, TextInput, required} from "react-admin";
import {EditableDatagrid, RowForm} from '@react-admin/ra-editable-datagrid';


const CompanyForm = props => (
    <RowForm {...props}>
        <TextInput source="name" validate={required()} fullWidth variant={'standard'}/>
    </RowForm>
);

const CompanyList = (props) => (
    <List {...props} title="Kundenverwaltung > Firmen">
        <EditableDatagrid
            rowClick="edit"
            undoable
            editForm={<CompanyForm/>}
        >
            <TextField source="name"/>
        </EditableDatagrid>
    </List>
);

export default CompanyList;