import {AppBar, UserMenu, MenuItemLink, useTranslate} from 'react-admin';
import {Typography, makeStyles, Box} from "@material-ui/core";
import React, {Fragment} from "react";
//import Logo from "../Media/logo.jpg"
import {forwardRef} from 'react';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    logo: {
        width: 150,
        height: 45.54,
        paddingLeft: 20,
        paddingRight: 20,
    },
});


const ManageUserMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/users"
            primaryText={translate('resources.users.name')}
            leftIcon={<PersonIcon/>}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});
const ManageUserGroupMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/user_groups"
            primaryText={translate('resources.user_groups.name')}
            leftIcon={<PeopleIcon/>}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const CustomUserMenu = (props) => (
    <UserMenu {...props}>
        <ManageUserMenu/>
        <ManageUserGroupMenu/>
    </UserMenu>
);

export const CustomAppBar = (props) => {
    const classes = useStyles();

    function handleChange(theme) {
        if (theme === true) {
            theme = false
        } else {
            theme = true
        }
    }

    return (
        <AppBar {...props} elevation={1} container={Fragment} userMenu={<CustomUserMenu/>}>
            <Box flex={1} display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    {/* <img src={Logo} alt={'Designpark Logo'} className={classes.logo}/>*/}
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.title}
                        id="react-admin-title"
                    />

                </Box>
                {/*<FormControlLabel
                    control={<Switch checked={props.theme} onChange={handleChange} name="gilad"/>}
                    label=""
                />*/}
            </Box>
        </AppBar>
    );
};