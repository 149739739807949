import React from "react";
import {
    AutocompleteInput,
    RadioButtonGroupInput,
    ReferenceInput,
    required,
    SimpleForm,
    useTranslate
} from "react-admin";
import {Create, DepaTextInput, DepaValidator} from "../../../DepaComponents/Components";
import Typography from "@material-ui/core/Typography";
import {AccordionSection} from "@react-admin/ra-form-layout";
import {Grid} from "@material-ui/core";


const SectionTitle = ({label}) => {
    const translate = useTranslate();
    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};
const ContactCreate = (props) => (
    <Create {...props} title="Kontakt erstellen">
        <SimpleForm onSubmit={props.save} redirect={'list'}>
            <Grid container spacing={1} xs={12} fullWidth>
                <Grid item xs={12}>
                    <SectionTitle label={'Identität'}/>
                </Grid>

                <DepaTextInput xs={12} md={6} source="firstName" label={'Vorname'}/>
                <DepaTextInput xs={12} md={6} source="surName" label={'Nachname'} validate={required()}/>
                <Grid item xs={12} md={4}>
                    <ReferenceInput
                        source="crmCompany"
                        reference="crm_companies"
                        label="Firma"
                        fullWidth
                    >
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                </Grid>
                <DepaTextInput xs={12} source="website" label={'Webseite'}/>
            </Grid>
            <AccordionSection label="Kontaktdaten" fullWidth defaultExpanded>
                <Grid container spacing={1} xs={12} fullWidth>
                    <DepaTextInput xs={12} md={8} source="street" label={'Straße'}/>
                    <DepaTextInput xs={12} md={4} source="postcode" label={'Postleitzahl'}/>
                    <DepaTextInput xs={12} md={4} source="city" label={'Stadt'}/>
                    <DepaTextInput xs={12} source="phone" label={'Telefonnummer'} validate={required()}/>
                    <DepaTextInput xs={12} source="email" label={'E-Mail'}
                                   validate={[required(), DepaValidator.validateEmail]}/>

                    <ReferenceInput
                        source="crmCountry"
                        reference="crm_countries"
                        label="Land"
                        validate={required()}
                    >
                        <RadioButtonGroupInput optionText="name"/>
                    </ReferenceInput>

                </Grid>
            </AccordionSection>
        </SimpleForm>
    </Create>
);
export default ContactCreate;