//import { default as CrmContactShow } from './show';
import { default as ServiceContractTypeCreate } from './create';
import { default as ServiceContractTypeList } from './list';
import { default as ServiceContractTypeEdit } from './edit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: ServiceContractTypeList,
    create: ServiceContractTypeCreate,
    edit: ServiceContractTypeEdit
    //show: CrmContactShow
};