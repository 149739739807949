import {DepaDialogEditButton, DepaTextInput} from "../../../DepaComponents/Components";
import {DateInput} from "../../../DepaComponents/DepaDatePicker/DepaDatePicker";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import {NumberInput, ReferenceInput, required, SelectInput, TextInput} from "react-admin";
import React from "react";
import {Grid, makeStyles, useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
    field: {
        display: "none"
    }
}));

/**
 * PopUp Edit Form for ServiceTasks
 * Renders one of two different Edit Forms depending on the window Size
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TaskEdit = (props) => {
    const classes = useStyles();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return <DepaDialogEditButton {...props} title={'Abrechnung Bearbeiten'}
                                 resource={'service_tasks'}
                                 label={''} enterNextDelay={500}>
        {isSmall ? (
            <Grid container spacing={2} xs={12} fullWidth>
                <Grid item xs={12}>
                    <DateInput source="taskDate" label={'resources.service_task.fields.task_date'}
                               validate={required()} fullWidth
                               options={{format: "d MMM yyyy", clearable: true}}
                               providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                </Grid>
                <Grid item xs={12}>
                    <NumberInput source={'minutes'} validate={required()}
                                 label={'resources.service_task.fields.minutes'} fullWidth
                                 min={0}/>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput reference={'service_contract_rates'} source={'serviceContractRate'}
                                    label={'resources.service_task.fields.service_contract_rate'} fullWidth
                                    filter={{serviceContract: props.record.serviceContract}}
                                    validate={required()}>
                        <SelectInput optionText={'identifier'}/>
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput reference={'service_task_templates'} source={'serviceTaskTemplates'}
                                    label={'resources.service_task.fields.service_task_templates'}
                                    fullWidth>
                        <SelectInput optionText={'identifier'}/>
                    </ReferenceInput>
                </Grid>
                <DepaTextInput xs={12}
                               source={'description'}
                               label={'resources.service_task.fields.description'}
                               rows={3}
                               multiline/>

                <DepaTextInput xs={12}
                               source={'comment'}
                               label={'resources.service_task.fields.comment'}
                               rows={3}
                               multiline/>

                <TextInput source={'serviceContract'}
                           defaultValue={props.id}
                           className={classes.field}/>
            </Grid>
        ) : (
            <Grid container spacing={2} xs={12} fullWidth>
                <Grid item xs={12} md={6}>
                    <DateInput source="taskDate" label={'resources.service_task.fields.task_date'}
                               validate={required()} fullWidth
                               options={{format: "d MMM yyyy", clearable: true}}
                               providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                </Grid>

                <Grid item xs={12} md={6}>
                    <ReferenceInput reference={'service_contract_rates'} source={'serviceContractRate'}
                                    label={'resources.service_task.fields.service_contract_rate'} fullWidth
                                    filter={{serviceContract: props.record.serviceContract}}
                                    validate={required()}>
                        <SelectInput optionText={'identifier'}/>
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <NumberInput source={'minutes'} validate={required()}
                                 label={'resources.service_task.fields.minutes'} fullWidth
                                 min={0}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ReferenceInput reference={'service_task_templates'} source={'serviceTaskTemplates'}
                                    label={'resources.service_task.fields.service_task_templates'}
                                    resettable
                                    fullWidth>
                        <SelectInput optionText={'identifier'}/>
                    </ReferenceInput>
                </Grid>
                <DepaTextInput xs={12}
                               source={'description'}
                               label={'resources.service_task.fields.description'}
                               rows={3}
                               multiline/>

                <DepaTextInput xs={12}
                               source={'comment'}
                               label={'resources.service_task.fields.comment'}
                               rows={3}
                               multiline/>

                <TextInput source={'serviceContract'}
                           defaultValue={props.id}
                           className={classes.field}/>
            </Grid>
        )}
    </DepaDialogEditButton>
}

export default TaskEdit;