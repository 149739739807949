import {DepaDialogEditButton, DepaTextInput} from "../../../DepaComponents/Components";
import {DateInput} from "../../../DepaComponents/DepaDatePicker/DepaDatePicker";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import {TextInput} from "react-admin";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
    field: {
        display: "none"
    }
}));

/**
 * PopUp Edit Form for ServiceContractComments
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CommentEdit = (props) => {
    const classes = useStyles();
    return <DepaDialogEditButton {...props} title={'Kommentar Bearbeiten'}
                                 resource={'service_contract_comments'}
                                 label={''} enterNextDelay={500} fullWidth>
        {/* <DepaTextInput source={'identifier'}
                       label={'resources.service_contract_comments.fields.identifier'}/>*/}
        <DateInput source="commentDate"
                   label={'resources.service_contract_comments.fields.comment_date'} fullWidth
                   options={{format: "d MMM yyyy", clearable: true}}
                   providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
        <DepaTextInput source={'comment'}
                       label={'resources.service_contract_comments.fields.comments'} multiline
                       rows={4}/>
        <TextInput source={'serviceContract'}
                   defaultValue={props.id}
                   className={classes.field}
        />
    </DepaDialogEditButton>
}

export default CommentEdit;