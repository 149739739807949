import React from "react";
import {Datagrid, TextField, useTranslate, ShowButton, EditButton} from "react-admin";
import {DepaTooltip, List, DepaActionColStyles} from "../../../DepaComponents/Components";
import {Fade, Card, CardContent} from "@material-ui/core";

const TaskTemplatePanel = ({ record}) => {
    const translate = useTranslate();
    return <Card title={'resources.task_templates.fields.content'}>
        <CardContent > {translate('resources.task_templates.fields.content')}: </CardContent>
        <CardContent dangerouslySetInnerHTML={{__html: record.content}}/>
    </Card>
};

const TaskTemplateList = (props) => {
    const classes = DepaActionColStyles()
    return (<List {...props} title="resources.task_templates.title">
            <Datagrid
                expand={<TaskTemplatePanel/>}
                rowClick={'edit'}
                optimized
            >
                <TextField source="identifier" label="resources.task_templates.fields.identifier"/>
                <DepaTooltip title="Anzeigen" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                             leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                    <ShowButton label={''}/>
                </DepaTooltip>
                <DepaTooltip title="Bearbeiten" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                             leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                    <EditButton label={''}/>
                </DepaTooltip>
            </Datagrid>
        </List>
    );
};

export default TaskTemplateList;