import React from "react";
import {TextField, Datagrid, EditButton, ReferenceArrayField, SingleFieldList, ChipField} from "react-admin";
import {List} from "@react-admin/ra-enterprise";
import {DepaTopListToolbar, DepaActionColStyles, DepaTooltip} from "../../../DepaComponents/Components";
import {DeleteRowButton} from "@react-admin/ra-editable-datagrid";
import {Fade} from "@material-ui/core";


const ApuUserList = (props) => {
    const classes= DepaActionColStyles();
    return (
        <>
            <List {...props} title="Liste aller Nutzer" actions={<DepaTopListToolbar/>}>
                <Datagrid
                    rowClick="edit" optimized>
                    <TextField source="username" label={'Nutzername'}/>
                    <ReferenceArrayField label="Benutzergruppe" reference="user_groups" source="apiGroup">
                        <SingleFieldList linkType={false}>
                            <ChipField source="groupname" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <DepaTooltip title="Bearbeiten" aria-label="add" arrow TransitionComponent={Fade}
                                 enterDelay={500} leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                        <EditButton label={''} />
                    </DepaTooltip>
                    <DeleteRowButton undoable={true} cellClassName={classes.depaActionCol} />
                </Datagrid>
            </List>
        </>
    )
};

export default ApuUserList;