import React from "react";
import {BooleanInput, PasswordInput, ReferenceArrayInput, required} from "react-admin";
import {Edit} from "@react-admin/ra-enterprise";
import {DualListInput} from "@react-admin/ra-relationships";
import {DepaSimpleForm, DepaTextInput, DepaValidator} from "../../../DepaComponents/Components";
import {Grid} from "@material-ui/core";

export const validatePasswords = ({
                                      password,
                                      confirm_password,
                                  }) => {
    const errors = {};

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = [
            'Muss mit Password übereinstimmen',
        ];
    }

    return errors;
};

const ApiUserEdit = (props) => (
    <Edit {...props} title="Benutzer bearbeiten">
        <DepaSimpleForm redirect={'list'} validate={validatePasswords}>
            <Grid container xs={12} fullWidth spacing={2}>
                <DepaTextInput xs={12} md={6} source="username" validate={required()} fullWidth/>
                <DepaTextInput xs={12} md={6} source="email" validate={[required(), DepaValidator.validateEmail]}
                               fullWidth/>
                <Grid item xs={12} md={6}>
                    <PasswordInput
                        fullWidth
                        source="password"
                        label={'Passwort'}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <PasswordInput
                        fullWidth
                        source="confirm_password"
                        label={'Bestätige Password'}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BooleanInput source={'isActive'} label={'Aktiv'}/>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceArrayInput reference={'user_groups'} source={'apiGroup'}
                                         label={'Benutzergruppe'}
                                         fullWidth>
                        <DualListInput source={'groupname'} optionText={'groupname'} addButtonLabel={'Auswählen'}
                                       removeButtonLabel={'Zurücklegen'}/>
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
        </DepaSimpleForm>
    </Edit>
);
export default ApiUserEdit;