import * as React from 'react';
import { cloneElement } from 'react';
import {
    useListContext,
    TopToolbar,
    sanitizeListRestProps,
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import { Fab,Zoom } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {Breadcrumb, ResourceBreadcrumbItems} from '@react-admin/ra-navigation';

const MyBreadcrumb = (props) => (
    <Breadcrumb {...props}>
        <ResourceBreadcrumbItems />
    </Breadcrumb>
);
const DepaTopListToolbar = (props, record) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>

            <MyBreadcrumb variant={'actions'}/>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {props.children}
            <Tooltip title="Neuer Eintrag" aria-label="add" arrow TransitionComponent={Zoom}>
                <Fab color="primary" className="ms-4" aria-label="add" href={`#/${props.resource}/create`}>
                    <AddIcon />
                </Fab>
            </Tooltip>
        </TopToolbar>
    );
};

export default DepaTopListToolbar;