import React from 'react';
import {SimpleForm as RaSimpleForm} from "react-admin";
import DefaultToolbar from "./DefaultToolbar";

const DepaSimpleForm = RaSimpleForm;

DepaSimpleForm.defaultProps = {
    toolbar: <DefaultToolbar/>
};

export default DepaSimpleForm;