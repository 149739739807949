import React from "react";
import {FieldGuesser} from "@api-platform/admin";
import {AutocompleteInput, Datagrid, Filter, ReferenceInput, SelectInput, TextField, TextInput} from "react-admin";
import {List} from "../../../DepaComponents/Components";

/**
 * Input Filter for CrmContact
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ContactFilters = (props) => (
    <Filter {...props}>
        <TextInput source="firstName" label={'Vorname'}/>
        <TextInput source="surName" label={'Nachname'}/>
        <TextInput source="city" label={'Stadt'}/>
        <ReferenceInput
            source="crmCompany"
            reference="crm_companies"
            label="Firma"
            fullWidth
            format={v => v !== null && typeof v === 'object' && v.hasOwnProperty('@id') ? v['@id'] : v}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput
            source="crmCountry"
            reference="crm_countries"
            label="Land"
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>
    </Filter>
);

/**
 * CrmContact List View
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ContactList = (props) => (
    <List {...props} title="Kundenverwaltung > Kunden" filters={<ContactFilters/>}>
        <Datagrid
            rowClick={'edit'}
            optimized
        >
            <FieldGuesser source="firstName" label={'Vorname'}/>
            <FieldGuesser source="surName" label={'Nachname'}/>
            <TextField label="Firma" source="crmCompany.name"/>
        </Datagrid>
    </List>
);

export default ContactList;