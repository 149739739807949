import React from "react";
import {
    required,
    SimpleForm,
    useTranslate,
} from "react-admin";
import {Create, DepaTextInput,} from "../../../DepaComponents/Components";
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";


const SectionTitle = ({label}) => {
    const translate = useTranslate();
    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};
const TaskTemplateCreate = (props) => (
    <Create {...props} title="resources.task_templates.create">
        <SimpleForm onSubmit={props.save} redirect={'list'}>
            <Grid container spacing={1} xs={12} fullWidth>
                <Grid item xs={12}>
                    <SectionTitle label={'Abrechnung Vorlage'}/>
                </Grid>
                <DepaTextInput xs={12} md={4} source={'identifier'} label={'resources.task_templates.fields.identifier'}
                               validate={required()}/>
                <DepaTextInput multiline rows={4} xs={12} source={'content'}
                               label={'resources.task_templates.fields.content'}/>
            </Grid>
        </SimpleForm>
    </Create>
);
export default TaskTemplateCreate;