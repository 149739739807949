import React from "react";
import {Create, DepaTextInput} from "../../../DepaComponents/Components";
import {SimpleForm} from "react-admin";

const CountryCreate = (props) => (
    <Create {...props} title="Land erstellen">
        <SimpleForm onSubmit={props.save} redirect={'list'}>
            <DepaTextInput source="identifier"/>
            <DepaTextInput source="name"/>
            <DepaTextInput source="countryCode"/>
        </SimpleForm>
    </Create>
);
export default CountryCreate;