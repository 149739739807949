import React from "react";
import {List} from "../../../DepaComponents/Components";
import {Datagrid, UrlField, ReferenceField, ChipField} from "react-admin";


const PdfList = (props) => (
    <List {...props} title="Kundenverwaltung > Pdf">
        <Datagrid optimized>
            <ReferenceField reference={'service_contracts'} source={'serviceContract'} label={'Vertrag'}
                            format={v => v !== null && typeof v === 'object' && v.hasOwnProperty('@id') ? v['@id'] : v}>
               <ChipField source={'identifier'}/>
            </ReferenceField>
            <UrlField source="contentUrl" label="Datei" />
        </Datagrid>
    </List>
);

export default PdfList;