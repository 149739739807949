import React from "react";
import {
    ReferenceInput,
    required,
    SimpleForm,
    useTranslate, SelectInput
} from "react-admin";
import {DepaNumberInput, Edit} from "../../../DepaComponents/Components";
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";
import {DateInput} from "../../../DepaComponents/DepaDatePicker/DepaDatePicker";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";

const SectionTitle = ({label}) => {
    const translate = useTranslate();
    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const BillEdit = (props) => (
    <Edit {...props} title="resources.service_bills.edit">
            <SimpleForm onSubmit={props.save} redirect={'list'}>
                <Grid container spacing={1} xs={12} fullWidth>
                    <Grid item xs={12}>
                        <SectionTitle label={'Rechnung'}/>
                    </Grid>
                    <DepaNumberInput xs={12} md={6} source={'minutes'} label={'resources.service_bills.fields.minutes'} validate={required()}/>
                    <Grid item xs={12} md={6}>
                        <DateInput source="billDate" label={'resources.service_bills.fields.bill_date'} fullWidth
                                   options={{format: "d MMM yyyy", clearable: true}}
                                   providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                    </Grid>
                    <ReferenceInput reference={'service_contracts'} source={'serviceContract'} label={'resources.service_bills.fields.service_contract'}  fullWidth  validate={required()}
                                    format={v => v !== null && typeof v === 'object' && v.hasOwnProperty('@id') ? v['@id'] : v}
                    >
                        <SelectInput optionText={'identifier'}/>
                    </ReferenceInput>
                </Grid>
            </SimpleForm>
    </Edit>
);
export default BillEdit;