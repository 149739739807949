import {TextInput} from "react-admin";
import {Grid, InputAdornment} from "@material-ui/core";
import React from "react";
import {DepaValidator} from "../Components";

function DepaPriceInput(props) {
    return (
        <Grid xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} item={props.item}>
            <TextInput source={props.source}
                       label={props.label}
                       type={props.type}
                       resettable={props.resettable}
                       multiline={props.multiline}
                       fullWidth={props.fullWidth}
                       validate={DepaValidator.validatePrice}
                       defaultValue={props.defaultValue}
                       variant={props.variant}
                       parse={v => v.replace(",", ".")}
                       InputProps={{
                           startAdornment: (
                               <InputAdornment position="start">
                                   €
                               </InputAdornment>
                           ),
                       }}
            />
        </Grid>
    )
}

DepaPriceInput.defaultProps = {
    xl: false,
    lg: false,
    md: false,
    sm: false,
    xs: false,
    resettable: true,
    item: true,
    fullWidth: true,
    type: "text",
    multiline: false,
    variant: 'filled',
}

export default DepaPriceInput;