import {default as ServiceContractsList} from './list';
import {default as ServiceContractsCreate} from './create';
import {default as ServiceContractsEdit} from './edit';
import {default as ServiceContractsShow} from './show';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: ServiceContractsList,
    create: ServiceContractsCreate,
    edit: ServiceContractsEdit,
    show: ServiceContractsShow
};