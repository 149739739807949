import React from 'react';
import { Create as ReactAdminCreate } from '@react-admin/ra-enterprise';
import {CreateActions} from './CreateActions';

const Create = ReactAdminCreate;

Create.defaultProps = {
    actions: <CreateActions />,
};

export default Create;