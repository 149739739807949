import React from "react";
import {
    Datagrid,
    ReferenceField,
    ChipField,
    ShowButton,
    EditButton,
    useTranslate, TextInput, Filter, ReferenceInput, required, SelectInput
} from "react-admin";
import {DepaTooltip, List, DepaActionColStyles} from "../../../DepaComponents/Components";
import {Card, CardContent, Fade} from "@material-ui/core";

const TaskPanel = ({record}) => {
    const translate = useTranslate();
    return <Card title={'resources.contract_type.fields.description'}>
        <CardContent> {translate('resources.contract_type.fields.description')}: </CardContent>
        <CardContent dangerouslySetInnerHTML={{__html: record.description}}/>
    </Card>
};

const TaskFilters = (props) => (
    <Filter {...props}>
        <TextInput label="resources.service_task.fields.service_contract" source="serviceContract.identifier"/>
        <ReferenceInput reference={'service_contract_rates'} source={'serviceContractRate'}
                        label={'resources.service_task.fields.service_contract_rate'} fullWidth validate={required()}>
            <SelectInput optionText={'identifier'}/>
        </ReferenceInput>
        <ReferenceInput reference={'service_task_templates'} source={'serviceTaskTemplates'}
                        label={'resources.service_task.fields.service_task_templates'} fullWidth>
            <SelectInput optionText={'identifier'}/>
        </ReferenceInput>
    </Filter>
);

const TaskList = (props) => {
    const classes = DepaActionColStyles()
    return (<List {...props} title="resources.service_task.title" filters={<TaskFilters/>}>
            <Datagrid
                rowClick={'edit'}
                optimized
                expand={<TaskPanel/>}
            >
                <ReferenceField reference={'service_contracts'} source={'serviceContract'}
                                label={'resources.service_task.fields.service_contract'}>
                    <ChipField source={'identifier'} optionText={'identifier'}/>
                </ReferenceField>
                <ReferenceField reference={'service_contract_rates'} source={'serviceContractRate'}
                                label={'resources.service_task.fields.service_contract_rate'}>
                    <ChipField source={'identifier'} optionText={'identifier'}/>
                </ReferenceField>
                <ReferenceField reference={'service_task_templates'} source={'serviceTaskTemplates'}
                                label={'resources.service_task.fields.service_task_templates'}>
                    <ChipField source={'identifier'} optionText={'identifier'}/>
                </ReferenceField>
                <DepaTooltip title="Anzeigen" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                             leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                    <ShowButton label={''}/>
                </DepaTooltip>
                <DepaTooltip title="Bearbeiten" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                             leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                    <EditButton label={''}/>
                </DepaTooltip>
            </Datagrid>
        </List>
    );
};

export default TaskList;