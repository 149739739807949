import React from "react";
import {required, TextInput, SimpleForm} from "react-admin";
import {Edit} from "@react-admin/ra-enterprise";

const GroupEdit = (props) => (
    <Edit {...props} title="Benutzergruppe erstellen" >
        <SimpleForm redirect={'list'}>
            <TextInput source="groupname" validate={required()}  fullWidth/>
        </SimpleForm>
    </Edit>
);
export default GroupEdit;