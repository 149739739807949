// in src/Dashboard.js
import * as React from "react";
import Card from '@material-ui/core/Card';
import {CardActionArea} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import {Title} from 'react-admin';
import {Grid, Typography} from "@material-ui/core";

const styles = {
    root: {
        flexGrow: 1,
        paddingTop: 20
    },
    media: {
        height: 200,
    },
    contentCard: {
        minHeight: 140,
        borderRadius: 0
    },
    card: {
        borderRadius: 0
    }
};
/**
 * Dashboard/Home
 * @returns {JSX.Element}
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return <>
        <div style={styles.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {/*<Card style={styles.card}>*/}
                    <Title title={"Dashboard"}/>
                    {/*  <CardMedia
                            style={styles.media}
                            image="#"
                            title="#"
                        />*/}
                    {/*</Card>*/}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Die wichtigsten Bereiche im Überblick:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CardActionArea href={'#/service_contracts'}>
                        <Card style={styles.contentCard}>
                            <CardContent>
                                {/*<DescriptionOutlinedIcon />*/}
                                <Typography variant="h6" gutterBottom>
                                    Service Verträge
                                </Typography>
                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CardActionArea href={'#/service_tasks'}>
                        <Card style={styles.contentCard}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Abrechnungen
                                </Typography>
                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CardActionArea href={'#/service_bills'}>
                        <Card style={styles.contentCard}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Rechnungen
                                </Typography>
                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid>
            </Grid>
        </div>
        <div style={styles.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Bereiche für Administratoren:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CardActionArea href={'#/users'}>
                        <Card style={styles.contentCard}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Benutzer
                                </Typography>
                            </CardContent>

                        </Card>
                    </CardActionArea>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CardActionArea href={'#/user_groups'}>
                        <Card style={styles.contentCard}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Benutzer Rollen
                                </Typography>
                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid>
            </Grid>
        </div>
    </>
};