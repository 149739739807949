import React from "react";
import {
    HydraAdmin,
    fetchHydra as baseFetchHydra,
    useIntrospection,
    hydraDataProvider,
    ResourceGuesser
} from "@api-platform/admin";
import {parseHydraDocumentation} from "@api-platform/api-doc-parser";
import CustomLayout from "./Layout /Layout";
import i18nProvider from "./i18nProvider";
import Dashboard from './app/Dashboard';
import ServiceContract from "./Components/Service/Contract/index";
import ServiceBill from "./Components/Service/Bill"
import ServiceContractType from "./Components/Service/ContractType"
import ServiceTask from "./Components/Service/Task"
import ServiceTaskTemplate from "./Components/Service/TaskTemplate"
import ServiceContractRate from "./Components/Service/ContractRate"
import CrmCompany from "./Components/Crm/Company/index";
import CrmCountry from "./Components/Crm/Country/index";
import CrmContact from "./Components/Crm/Contact/index";
import {ServiceContractsPdfCreate, ServiceContractsPdfList} from "./Components/Service/ContractPdf/index"
import {Redirect, Route} from "react-router-dom";
import authProvider from "./AuthProvider/authProvider";
import ApiUser from "./Components/ApiAuth/Users/index"
import ApiGroup from "./Components/ApiAuth/UserGroups/index"
import CustomLogin from "./Layout /Login";

const entrypoint = process.env.REACT_APP_API_URL;

//Authentication
const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};
const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });
const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login"/>;
};
const apiDocumentationParser = async (entrypoint) => {
    try {
        const {api} = await parseHydraDocumentation(entrypoint, {headers: getHeaders});
        return {api};
    } catch (result) {
        if (result.status === 401) {
            // Prevent infinite loop if the token is expired
            localStorage.removeItem("token");

            return {
                api: result.api,
                customRoutes: [
                    <Route path="/" component={RedirectToLogin}/>
                ],
            };
        }

        throw result;
    }
};

const dataProvider = hydraDataProvider(
    entrypoint,
    fetchHydra,
    parseHydraDocumentation,
    true // useEmbedded parameter
    , apiDocumentationParser,
);

const Admin = () => (
    <HydraAdmin
        disableTelemetry
        authProvider={authProvider}
        layout={CustomLayout}
        dashboard={Dashboard}
        loginPage={CustomLogin}
        entrypoint={entrypoint}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
    >
        <ResourceGuesser name="service_contracts"
                         options={{label: 'resources.contracts.name'}}
                         {...ServiceContract}
        />
        <ResourceGuesser name="crm_contacts"
                         options={{label: 'resources.customers.name'}}
                         {...CrmContact}
        />
        <ResourceGuesser name="crm_companies"
                         options={{label: 'resources.company.name'}}
                         {...CrmCompany}
        />
        <ResourceGuesser name="crm_countries"
                         options={{label: 'resources.country.name'}}
                         {...CrmCountry}
        />
        <ResourceGuesser name="service_contract_pdfs"
                         options={{label: 'resources.contract_pdfs.name'}}
                         create={ServiceContractsPdfCreate}
                         list={ServiceContractsPdfList}
        />
        <ResourceGuesser name="service_bills"
                         options={{label: 'resources.service_bills.name'}}
                         {...ServiceBill}

        />
        <ResourceGuesser name="service_contract_rates"
                         options={{label: 'resources.service_contract_rate.name'}}
                         {...ServiceContractRate}

        />
        <ResourceGuesser name="service_contract_types"
                         options={{label: 'resources.contract_type.name'}}
                         {...ServiceContractType}

        />
        <ResourceGuesser name="service_tasks"
                         options={{label: 'resources.service_task.name'}}
                         {...ServiceTask}

        />
        <ResourceGuesser name="service_task_templates"
                         options={{label: 'resources.task_templates.name'}}
                         {...ServiceTaskTemplate}
        />
        <ResourceGuesser name={"users"}
                         options={{label: 'Benutzer'}}
                         {...ApiUser}
        />
        <ResourceGuesser name={"user_groups"}
                         options={{label: 'Benutzergruppen'}}
                         {...ApiGroup}
        />
        <ResourceGuesser name={"service_contract_comments"}
        />
        <ResourceGuesser name={"service_contract_comments_files"}
        />
    </HydraAdmin>
);

export default Admin;