import {ListButton, SaveButton, Toolbar} from "react-admin";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    returnButton: {
        marginLeft: "auto"
    }
});

const DefaultToolbar = props => {
    const classes = useStyles()
    return <Toolbar {...props}>
        <SaveButton
            redirect={'edit'}
            submitOnEnter={true}
            disabled={props.pristine}
        />
        <SaveButton
            label={'Speichern & Schließen'}
            redirect={'list'}
            submitOnEnter={false}
            variant="text"
            disabled={props.pristine}
        />
        <ListButton
            basePath={props.basePath}
            className={classes.returnButton}
        />
    </Toolbar>
};

export default DefaultToolbar;