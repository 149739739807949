import DepaDialogEditButton from "../../../DepaComponents/DepaDialogEditButton";
import {
    ReferenceManyField,
    required,
    TextInput, FileInput, FileField, Datagrid, UrlField,
} from "react-admin";
import {DeleteRowButton,} from "@react-admin/ra-editable-datagrid";
import DepaDialogCreateButton from "../../../DepaComponents/DepaDialogCreateButton";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {Badge} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    field: {
        display: "none"
    },
}));

export const CommentFileView = ({record: data}) => {
    const classes = useStyles()
    const icon = () => {
        if (data.serviceContractCommentsFile === undefined) {
            return <Badge badgeContent={0}
                          color="primary"
                          variant="dot"><AttachFileIcon/></Badge>
        } else {
            return <Badge badgeContent={data.serviceContractCommentsFile.length}
                          color="primary"
                          variant="dot"><AttachFileIcon/> </Badge>
        }
    }
    return (
        <DepaDialogEditButton label={''} fullWidth fullScreen={false}
                              icon={icon()}
                              title={'Dateien Bearbeiten'} tooltipTitle={'Datei bearbeiten'} saveButton={false}>
            <ReferenceManyField reference="service_contract_comments_files" target='serviceContractComment'
                                addLabel={false}>
                <Datagrid
                    undoable
                >
                    <UrlField source='contentUrl' target={'_blank'} label={'Datei'} cellClassName={'w-auto'} fullWidth/>
                    <DeleteRowButton redirect={''} undoable={true}/>
                </Datagrid>
            </ReferenceManyField>
            {data.serviceContractCommentsFile === undefined ?
                <DepaDialogCreateButton resource={'service_contract_comments_files'}>
                    <FileInput source="file" validate={required()}
                               fullWidth>
                        <FileField source="src" title="title"/>
                    </FileInput>
                    <TextInput source={'serviceContractComment'} fullWidth
                               label={'Comment'}
                               validate={required()}
                               defaultValue={data.id}
                               className={classes.field}
                    />
                </DepaDialogCreateButton> : false}
        </DepaDialogEditButton>
    )
}