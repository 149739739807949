import React, {useState} from 'react';
import {Button, FormWithRedirect, SaveButton, useNotify, useRefresh, useUpdate,} from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {Fade} from "@material-ui/core";
import {DepaTooltip} from "./Components";


const useStyles = makeStyles({
    appBar: {
        //paddingBottom: 20
    },
    form: {
        paddingTop: '6rem'
    }
});

const styles = {
    button: {
        marginTop: '1em',
    }
};

const DepaDialogEditButton = (props) => {
    const [editShowDialog, setEditShowDialog] = useState(false);
    const [update, {loading}] = useUpdate(props.resource, props.record.id);
    const notify = useNotify();
    const refresh = useRefresh();
    const classes = useStyles();

    const handleClick = () => {
        setEditShowDialog(true);
    };

    const handleCloseClick = () => {
        setEditShowDialog(false);
    };
    const handleSubmit = async values => {
        update(
            {payload: {data: values}},
            {
                mutationMode: 'undoable',
                onSuccess: ({data}) => {
                    setEditShowDialog(false);
                    notify(`Element wurde aktualisiert`, undefined, undefined, true);
                    //refresh page after
                    refresh();
                },
                onFailure: (error) => {
                    notify(`Fehler: ${error.message}`, 'warning');
                }
            }
        );
    };

    return (
        <>
            <DepaTooltip title={props.tooltipTitle} aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                         leaveDelay={0} enterNextDelay={500}>
                <Button onClick={handleClick} label={props.label}>
                    {props.icon}
                </Button>
            </DepaTooltip>
            <Dialog
                fullWidth={props.fullWidth}
                fullScreen={props.fullScreen}
                maxWidth={props.maxWidth}
                open={editShowDialog}
                onClose={handleCloseClick}
                aria-label={'Bearbeiten'}

            >
                {props.fullScreen === false ? <DialogTitle>{props.title}</DialogTitle> : null}

                <FormWithRedirect
                    style={{paddingTop: 40}}
                    record={props.record}
                    resource={props.resource}
                    save={handleSubmit}
                    render={({
                                 handleSubmitWithRedirect,
                                 pristine,
                                 saving
                             }) => (
                        <>
                            {props.fullScreen === true ? <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleCloseClick}
                                                aria-label="close">
                                        <CloseIcon/>
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        {props.title}
                                    </Typography>


                                </Toolbar>
                            </AppBar> : false}
                            <DialogContent className={({
                                [classes.form]: props.fullScreen,
                            })}>
                                {props.children}
                            </DialogContent>
                            {props.toolbar === true ? <DialogActions>
                                {props.cancelButton === true ? <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel/>
                                </Button> : false}
                                {props.saveButton === true ? < SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                /> : false}
                            </DialogActions> : false}
                        </>
                    )}
                />
            </Dialog>
        </>
    );
};

DepaDialogEditButton.defaultProps = {
    label: "ra.action.edit",
    resource: false,
    record: false,
    maxWidth: false,
    fullScreen: false,
    fullWidth: false,
    title: "Bearbeiten",
    icon: <EditIcon/>,
    tooltipTitle: "Bearbeiten",
    toolbar: true,
    cancelButton: true,
    saveButton: true,
};

export default withStyles(styles)(DepaDialogEditButton);