import React from "react";
import {TextInput, required, TextField, Filter, SearchInput} from "react-admin";
import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import {List} from "@react-admin/ra-enterprise";
import {DepaTopListToolbar} from "../../../DepaComponents/Components";

function CategoryForm(props) {
    return(
        <RowForm {...props} >
            <TextInput source="groupname" validate={required()} variant="standard" fullWidth/>
        </RowForm>)
};

const CategoryFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="groupname" label={'Name'}/>
    </Filter>
)

const GroupList = (props) => {
  return(
      <List title="Liste aller Benutzergruppen"
          {...props}
          filters={<CategoryFilter/>}
          actions={<DepaTopListToolbar/>}
    >
        <EditableDatagrid
            rowClick="edit"
            undoable
            editForm={<CategoryForm/>}
        >
            <TextField source="groupname" label={'Name'}/>
        </EditableDatagrid>
    </List>
);
};

export default GroupList;