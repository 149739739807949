//import { default as ServiceTaskTemplateShow } from './show';
import { default as ServiceTaskTemplateCreate } from './create';
import { default as ServiceTaskTemplateList } from './list';
import { default as ServiceTaskTemplateEdit } from './edit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: ServiceTaskTemplateList,
    create: ServiceTaskTemplateCreate,
    edit: ServiceTaskTemplateEdit
    //show: CrmContactShow
};