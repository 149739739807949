import React from "react";
import {
    required,
    SimpleForm,
    useTranslate,
} from "react-admin";
import {Create, DepaRichTextInput, DepaTextInput,} from "../../../DepaComponents/Components";
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";


const SectionTitle = ({label}) => {
    const translate = useTranslate();
    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};
const ContactCreate = (props) => (
    <Create {...props} title="resources.contract_type.create">
        <SimpleForm onSubmit={props.save} redirect={'list'}>
            <Grid container spacing={1} xs={12} fullWidth>
                <Grid item xs={12}>
                    <SectionTitle label={'Vertragsart'}/>
                </Grid>
                <DepaTextInput xs={12} md={4} source={'identifier'} label={'resources.contract_type.fields.identifier'}
                               validate={required()}/>
                <DepaRichTextInput xs={12} source={'description'} label={'resources.contract_type.fields.description'}/>
            </Grid>
        </SimpleForm>
    </Create>
);
export default ContactCreate;