import {Grid} from "@material-ui/core";
import React from "react";
import Quill from "quill";
import htmlEditButton from "quill-html-edit-button";
import RichTextInput from "ra-input-rich-text";

function DepaRichTextInput(props) {
    Quill.register({
        "modules/htmlEditButton": htmlEditButton
    })
    return (
        <Grid xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} item={props.item}>
            <RichTextInput source={props.source}
                           label={props.label}
                           type={props.type}
                           fullWidth={props.fullWidth}
                           validate={props.validate}
                           defaultValue={props.defaultValue}
                           options={{
                               modules: {
                                   htmlEditButton: {
                                       msg: "Html Sourcecode",
                                   },
                                   toolbar: [
                                       [{header: [1, 2, 3, 4, 5, 6, false]}],
                                       ['bold', 'italic', 'underline', 'strike'],
                                       ['link'],
                                       [{'script': 'sub'}, {'script': 'super'}],
                                       [{'list': 'ordered'}, {'list': 'bullet'}],
                                       ['clean']
                                   ]
                               },
                               theme: "snow"
                           }}
            />
        </Grid>
    )
}

DepaRichTextInput.defaultProps = {
    xl: false,
    lg: false,
    md: false,
    sm: false,
    xs: false,
    item: true,
    fullWidth: true,
    type: "text",
}

export default DepaRichTextInput;