import React from "react";
import Tooltip from '@material-ui/core/Tooltip';

// TooltipWrapper gets initial MyTooltip props without Tooltip props
// and passes them to children (an input component in this example)
const TooltipWrapper = React.forwardRef(({children, ...props}, ref) => (
    <div ref={ref}>{React.cloneElement(children, props)}</div>
));

// MyTooltip gets explicit props and props added by SimpleForm
// and passes them together to the Tooltip.
export const DepaTooltip = ({children, ...props}) => (
    <Tooltip {...props}>
        <TooltipWrapper>{children}</TooltipWrapper>
    </Tooltip>
);
