import React from "react";
import {DepaActionColStyles, DepaTooltip, List} from "../../../DepaComponents/Components";
import {
    BooleanField,
    Button,
    Datagrid,
    EditButton,
    linkToRecord,
    ReferenceField,
    ShowButton,
    TextField
} from 'react-admin';
import {Fade} from "@material-ui/core";
import {ContractPdfView} from "../ContractPdf/ContractPdfView";
import AsideFilter from "./AsideFilter";
import {Link} from "react-router-dom";
import EuroIcon from '@material-ui/icons/Euro';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CommentIcon from '@material-ui/icons/Comment';
import {FullNameField} from "./edit";
import NotesIcon from "@material-ui/icons/Notes";

const ManageCommentButton = ({record: data}) => (
    <DepaTooltip title="Kommentare bearbeiten" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                 leaveDelay={0} enterNextDelay={500}>
        <Button
            component={Link}
            to={linkToRecord('/service_contracts', data.id) + '/comments'}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <CommentIcon/>
        </Button>
    </DepaTooltip>
);


const ManageTasksButton = ({record: data}) => (
    <DepaTooltip title="Abrechnungen bearbeiten" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                 leaveDelay={0} enterNextDelay={500}>
        <Button
            component={Link}
            to={linkToRecord('/service_contracts', data.id) + '/tasks'}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <AssignmentIcon/>
        </Button>
    </DepaTooltip>
);


const ManageBillsButton = ({record: data}) => (
    <DepaTooltip title="Rechnungen bearbeiten" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                 leaveDelay={0} enterNextDelay={500}>
        <Button
            component={Link}
            to={linkToRecord('/service_contracts', data.id) + '/bills'}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <EuroIcon/>
        </Button>
    </DepaTooltip>
);

const ContractList = (props) => {
    const classes = DepaActionColStyles();
    return <List {...props} title="resources.contracts.title" aside={<AsideFilter/>}
                 filterDefaultValues={{'status': true}}>
        <Datagrid optimized>
            <TextField source={'identifier'} label={'resources.contracts.fields.identifier'}/>

            <ReferenceField reference={'crm_contacts'} source={'crmContact'}
                            label={'resources.contracts.fields.crm_contact'} link={false}>
                <FullNameField/>
            </ReferenceField>
            <TextField source="serviceContractType.identifier"
                       label={'resources.contracts.fields.service_contract_type'}/>
            <BooleanField source={'status'} label={'resources.contracts.fields.status'}/>
            <ContractPdfView cellClassName={classes.depaActionCol}/>
            <ManageCommentButton cellClassName={classes.depaActionCol}/>
            {/*  <ManageBillsButton cellClassName={classes.depaActionCol}/>
            <ManageTasksButton cellClassName={classes.depaActionCol}/>*/}
            <DepaTooltip title="Arbeitsnachweise" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                         leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                <ShowButton label={''} icon={<NotesIcon/>}/>
            </DepaTooltip>
            <DepaTooltip title="Bearbeiten" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                         leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                <EditButton label={''}/>
            </DepaTooltip>
        </Datagrid>
    </List>
};

export default ContractList;