import React from "react";
import {Create, DepaTextInput} from "../../../DepaComponents/Components";
import {SimpleForm} from "react-admin";

const CompanyCreate =(props) => (
    <Create {...props} title="Firma erstellen" >
        <SimpleForm onSubmit={props.save} redirect={'list'}>
            <DepaTextInput source="name" />
        </SimpleForm>
    </Create>
);
export default CompanyCreate;