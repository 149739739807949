import React from 'react';
import { List as ReactAdminList } from '@react-admin/ra-enterprise';
import {DepaTopListToolbar} from "../Components";

const List = ReactAdminList;

List.defaultProps = {
    actions: <DepaTopListToolbar/>,
};

export default List;