import { default as CrmCountryCreate } from './create';
import { default as CrmCountryList } from './list';
import { default as CrmCountryEdit } from './edit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: CrmCountryList,
    create: CrmCountryCreate,
    edit: CrmCountryEdit
    //show: CrmContactShow
};