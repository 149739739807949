import React from "react";
import {FieldGuesser} from "@api-platform/admin";
import {List} from "../../../DepaComponents/Components";
import {Datagrid} from "react-admin";


const CountryList = (props) => (
    <List {...props}  title="Kundenverwaltung > Länder">
        <Datagrid
            rowClick="edit"
            undoable
            optimized
        >
        <FieldGuesser source="identifier"/>
        <FieldGuesser source="name"/>
        </Datagrid>
    </List>
);

export default CountryList;