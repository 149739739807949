import jwtDecode from "jwt-decode";

export const getUsername = () => {
    try {
        return jwtDecode(localStorage.getItem("token"))?.username
    } catch (error) {
        if ([401, 403].includes(error?.status || error?.response?.status)) {
            localStorage.removeItem("token");
            return Promise.reject();
        }
        return Promise.resolve();
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    login: ({username, password}) => {
        const request = new Request(
            `${process.env.REACT_APP_TOKEN_URL}`,
            {
                method: "POST",
                body: JSON.stringify({username: username, password}),
                headers: new Headers({"Content-Type": "application/json"}),
            }
        );
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({token}) => {
                localStorage.setItem("token", token);
            });
    },
    logout: () => {
        localStorage.removeItem("token");
        return Promise.resolve();
    },
    checkAuth: () => {
        try {
            if (
                !localStorage.getItem("token") ||
                new Date().getTime() / 1000 >
                jwtDecode(localStorage.getItem("token"))?.exp
            ) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    },
    checkError: (err) => {
        if ([401, 403].includes(err?.status || err?.response?.status)) {
            localStorage.removeItem("token");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => Promise.resolve(),
    getIdentity: () => {
        try {
            return Promise.resolve({
                id: 0,
                fullName: jwtDecode(localStorage.getItem("token"))?.username

            })
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }

    }
};