import React from "react";
import {
    AutocompleteInput,
    RadioButtonGroupInput,
    ReferenceInput,
    required,
    SimpleForm,
    useTranslate
} from "react-admin";
import {DepaTextInput, DepaValidator, Edit} from "../../../DepaComponents/Components";
import Typography from "@material-ui/core/Typography";
import {AccordionSection} from "@react-admin/ra-form-layout";
import {Grid} from "@material-ui/core";

const SectionTitle = ({label}) => {
    const translate = useTranslate();
    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const ContactEdit = (props) => (
    <Edit {...props} title="Kontakt bearbeiten">
        <SimpleForm onSubmit={props.save} redirect={'list'}>
            <Grid container spacing={1} xs={12} fullWidth>
                <Grid item xs={12}>
                    <SectionTitle label={'Identität'}/>
                </Grid>

                <DepaTextInput xs={12} md={6} source="firstName" label={'Vorname'}/>
                <DepaTextInput xs={12} md={6} source="surName" label={'Nachname'} validate={required()}/>
                <Grid item xs={12} md={4}>
                    <ReferenceInput
                        source="crmCompany"
                        reference="crm_companies"
                        label="Firma"
                        fullWidth
                        format={v => v !== null && typeof v === 'object' && v.hasOwnProperty('@id') ? v['@id'] : v}
                    >
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                </Grid>
                <DepaTextInput xs={12} source="website" label={'Webseite'}/>
            </Grid>
            <AccordionSection label="Kontaktdaten" fullWidth defaultExpanded>
                <Grid container spacing={1} xs={12} fullWidth>
                    <DepaTextInput xs={12} md={8} source="street" label={'Straße'}/>
                    <DepaTextInput xs={12} md={4} source="postcode" label={'Postleitzahl'}/>
                    <DepaTextInput xs={12} md={4} source="city" label={'Stadt'}/>
                    <DepaTextInput xs={12} source="phone" label={'Telefonnummer'} validate={required()}/>
                    <DepaTextInput xs={12} source="email" label={'E-Mail'}
                                   validate={[required(), DepaValidator.validateEmail]}/>
                    <Grid item>
                        <ReferenceInput
                            source="crmCountry"
                            reference="crm_countries"
                            label="Land"
                        >
                            <RadioButtonGroupInput optionText="name"/>
                        </ReferenceInput>
                    </Grid>
                </Grid>
            </AccordionSection>
        </SimpleForm>
    </Edit>
);
export default ContactEdit;