import {Layout} from 'react-admin';
import {AppLocationContext} from "@react-admin/ra-navigation"
import {lightTheme} from "./Theme";
import {darkTheme} from "./Theme";
import React, {useState} from "react";
import Menu from "./Menu";
import {CustomAppBar} from "./AppBar";


const CustomLayout = (props) => {
    const [theme, setTheme] = useState(true);
    return <AppLocationContext hasDashboard={!!props.dashboard}>
        <Layout {...props}
                theme={theme ? (lightTheme()) : (darkTheme())}
                menu={Menu}
                appBar={CustomAppBar}
        />
    </AppLocationContext>;
}

export default CustomLayout;