import React from "react";
import {CreateGuesser} from "@api-platform/admin";
import {FileInput, FileField} from "react-admin";

const Create =(props) => (
    <CreateGuesser {...props} title="Pdf Hochladen" >
        <FileInput source="file" >
            <FileField src="src" title="title"/>
        </FileInput>
    </CreateGuesser>
);
export default Create;