import {email, minLength, required, maxLength, regex} from "react-admin";

const DepaValidator = function () {
    return {
        validateEmail: email(),
        validateName: [required(), minLength(2)],
        validatePrice :[required(), maxLength(7) , regex(/^(?:\d{0,5}\.\d{1,2})$|^\d{0,4}$/, 'Preis muss im Format: 0000.00 angeben werden' )],
    }
}();

export default DepaValidator;