//import { default as CrmContactShow } from './show';
import { default as CrmContactCreate } from './create';
import { default as CrmContactList } from './list';
import { default as CrmContactEdit } from './edit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: CrmContactList,
    create: CrmContactCreate,
    edit: CrmContactEdit
    //show: CrmContactShow
};