import polyglotI18nProvider from 'ra-i18n-polyglot';
import {mergeTranslations} from "react-admin";
import germanMessages from 'ra-language-german';

const customTranslation = {
    'ra-relationships':
        {
            duallistinput: {
                availableItems: 'Verfügbare Items',
                selectedItems: 'Ausgewählte Items'
            }
        },
    menu: {
        service: 'Service',
        crm: 'Kundenveraltung',
        config: 'Einstellungen'
    },
    resources: {
        contracts: {
            name: 'Verträge |||| Verträge',
            title: 'Service > Verträge',
            fields: {
                identifier: 'Bezeichner',
                status: 'Aktiv',
                service_contract_type: 'Vertragsart',
                date_start: 'Vertragsbeginn',
                date_end: 'Vertragsende',
                hoursPerMonth: 'Stunden pro Monat',
                monthMin: 'Mindestvertragsdauer (Monate)',
                noticePeriod: 'Kündigungsfrist (Monate)',
                billingCycle: 'Abrechnungsturnus (Monate)',
                comments: 'Kommentar',
                crm_contact: 'Kunde',
                files: 'Dateien',
                serviceBills: 'Rechnungen',
                serviceTasks: 'Abrechnungen',
                contract_rate: 'Vertragsrate'
            },
        },
        customers: {
            name: 'Kunden |||| Kunden',
            title: 'Kundenverwaltung > Kunden',
            fields: {
                first_name: 'Vorname',
                sur_name: 'Nachname',
                street: 'Straße',
                postcode: 'Postleitzahl',
                city: 'Stadt',
                email: 'E-Mail',
                phone: 'Telefonnummer',
                website: 'Webseite',
                crm_company: 'Firma',
                crm_country: 'Land'
            },
        },
        contract_pdfs: {
            name: 'Pdf |||| Pdf',
            title: 'Service > Pdf',
            fields: {
                file: 'Datei',
                contract: 'Vertrag',
            },
        },
        company: {
            name: 'Firma |||| Firma',
            title: 'Kundenveraltung > Firmen',
            fields: {
                name: 'Name',
            },
        },
        country: {
            name: 'Länder |||| Länder',
            title: 'Kundenverwaltung > Länder',
            fields: {
                identifier: 'Bezeichner',
                name: 'name',
                country_code: 'Landesvorwahl'
            },
        },
        service_bills: {
            name: 'Rechnungen |||| Rechnungen',
            form_name: 'Rechnungen',
            create: 'Rechnung erstellen',
            edit: "Rechnung bearbeiten",
            title: 'Service > Rechnungen',
            fields: {
                minutes: 'Minuten',
                bill_date: 'Rechnungsdatum',
                service_contract: 'Vertrag',
            },
        },
        task_templates: {
            name: 'Abrechnung Vorlage |||| Abrechnung Vorlage',
            title: 'Einstellungen > Abrechnung Vorlage',
            create: 'Abrechnung Vorlage erstellen',
            edit: 'Abrechnung Vorlage bearbeiten',
            fields: {
                identifier: 'Bezeichner',
                content: 'Inhalt',
            },
        },
        contract_type: {
            name: 'Vertragsart |||| Vertragsart',
            edit: 'Vertragsart bearbeiten',
            create: 'Vertragsart erstellen',
            title: 'Einstellungen > Vertragsart',
            fields: {
                identifier: 'Bezeichner',
                description: 'Beschreibung',
            },
        },
        service_task: {
            name: 'Abrechnungen |||| Abrechnungen',
            title: 'Service > Abrechnungen',
            create: 'Abrechnung erstellen',
            edit: 'Abrechnung bearbeiten',
            form_name: 'Abrechnung',
            fields: {
                description: 'Beschreibung',
                comment: 'Kommentar',
                service_contract: 'Service Vertrag',
                service_contract_rate: 'Vertragsrate',
                service_task_templates: 'Auftrags Vorlage',
                task_date: 'Datum',
                minutes: 'Minuten',
                task_author: 'Autor'

            },
        },
        service_contract_rate: {
            name: 'Vertragsrate |||| Vertragsrate',
            title: 'Einstellungen > Vertragsrate',
            create: 'Vertragsrate erstellen',
            edit: "Vertragsrate bearbeiten",
            fields: {
                hourly_rate: 'Stundensatz',
                hourly_rate_increase: 'Stundensatz erhöhung',
                identifier: 'Bezeichner',
                service_contract: 'Service Vertrag',
            },
        },
        service_contract_comments: {
            create: 'Kommentar hinzufügen',
            fields: {
                identifier: 'Bezeichner',
                comments: 'Bemerkungen',
                file: 'Datei',
                comment_date: 'Datum'
            }
        },
        users: {
            name: 'Nutzerverwaltung'
        },
        user_groups: {
            name: 'Nutzergruppen'
        }
    },
};
const customMessages = mergeTranslations(
    germanMessages,
    customTranslation
);
const i18nProvider = polyglotI18nProvider(locale => {
    return customMessages;
}, 'de');

export default i18nProvider;