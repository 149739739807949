import React from "react";
import {DepaActionColStyles} from "../../../DepaComponents/DepaActionColStyles";
import {Datagrid, Pagination, ReferenceManyField, TextField} from "react-admin";
import {ContractRateEdit} from "./ContractRateEdit";
import {DeleteRowButton} from "@react-admin/ra-editable-datagrid";

/**
 *
 * @param props
 * @constructor
 */
const ContractRateListView = (props) => {
    const rowStyle = DepaActionColStyles();
    return (
        <ReferenceManyField reference={"service_contract_rates"} target={"serviceContract"}
                            pagination={<Pagination/>}
                            sort={{field: "identifier", order: "asc"}}
                            perPage={10}>
            <Datagrid
                optimized
            >
                <TextField source="identifier" label="resources.service_contract_rate.fields.identifier"/>
                <TextField source="hourlyRate" label="resources.service_contract_rate.fields.hourly_rate"/>
                <TextField source="hourlyRateIncrease"
                           label="resources.service_contract_rate.fields.hourly_rate_increase"/>
                <ContractRateEdit cellClassName={rowStyle.depaActionCol}/>
                <DeleteRowButton redirect={''} undoable={true}
                                 cellClassName={rowStyle.depaActionCol}/>
            </Datagrid>
        </ReferenceManyField>
    )
}

export default ContractRateListView;