import {DepaDialogEditButton} from "../../../DepaComponents/Components";
import {DateInput} from "../../../DepaComponents/DepaDatePicker/DepaDatePicker";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import {NumberInput, required, TextInput} from "react-admin";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
    field: {
        display: "none"
    }
}));

/**
 * PopUp Edit Form for ServiceBills
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BillEdit = (props) => {
    const classes = useStyles();
    return <DepaDialogEditButton {...props} title={'Rechnung Bearbeiten'}
                                 resource={'service_bills'}
                                 label={''} enterNextDelay={500}>
        <DateInput source="billDate" label={'resources.service_bills.fields.bill_date'}
                   fullWidth
                   options={{format: "d MMM yyyy", clearable: true}}
                   providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
        <NumberInput source={'minutes'} label={'resources.service_bills.fields.minutes'}
                     fullWidth
                     validate={required()}/>

        <TextInput source={'serviceContract'}
                   validate={required()}
                   defaultValue={props.id}
                   className={classes.field}/>
    </DepaDialogEditButton>
}

export default BillEdit;