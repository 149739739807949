import React from "react";
import {required, PasswordInput, ReferenceArrayInput, SimpleForm, BooleanInput} from "react-admin";
import {Create} from "@react-admin/ra-enterprise";
import {DualListInput} from "@react-admin/ra-relationships";
import {DepaTextInput, DepaValidator} from "../../../DepaComponents/Components";
import {Grid} from "@material-ui/core";
import {validatePasswords} from "./edit";

const ApiUserCreate = (props) => (
    <Create {...props} title="Benutzer erstellen">
        <SimpleForm redirect={'list'} validate={validatePasswords}>
            <Grid container xs={12} fullWidth spacing={2}>
                <DepaTextInput xs={12} md={6} source="username" validate={required()} fullWidth/>
                <DepaTextInput xs={12} md={6} source="email" validate={[required(), DepaValidator.validateEmail]}
                               fullWidth/>
                <Grid item xs={12} md={6}>
                    <PasswordInput
                        fullWidth
                        source="password"
                        label={'Passwort'}
                        validate={required()}
                    />
                </Grid>

                <Grid item xs={12} md={6} >
                    <PasswordInput
                        fullWidth
                        validate={required()}
                        source="confirm_password"
                        label={'Bestätige Password'}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BooleanInput source={'isActive'} label={'Aktiv'} defaultValue={true}/>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceArrayInput reference={'user_groups'} source={'apiGroup'}
                                         label={'Benutzergruppe'}
                                         fullWidth>
                        <DualListInput source={'groupname'} optionText={'groupname'} addButtonLabel={'Auswählen'}
                                       removeButtonLabel={'Zurücklegen'}/>
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
export default ApiUserCreate;