import * as React from 'react';
import { Card as MuiCard , CardContent, withStyles } from '@material-ui/core';
import { FilterList, FilterListItem , useGetList, FilterLiveSearch} from 'react-admin';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckIcon from '@material-ui/icons/Check';

const AsideFilter = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {data, ids} = useGetList('service_contract_types',
        { page: 1, perPage: 100 },
        { field: 'identifier', order: 'ASC' },
        {}
    )
    const Card = withStyles(theme => ({
        root: {
            [theme.breakpoints.up('sm')]: {
                order: -1, // display on the left rather than on the right of the list
                width: '15em',
                marginRight: '1em',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    }))(MuiCard);

    const CategoryFilter = () => (
        <>
            <FilterLiveSearch source={'identifier'}/>
            <FilterList
                label="resources.contracts.fields.service_contract_type"
                icon={<DescriptionIcon />}
            >
                {ids &&
                data &&
                ids.map((id) => (
                    <FilterListItem
                        label={data[id].identifier}
                        key={data[id].id}
                        value={{ serviceContractType: data[id].id }}
                    />
                ))}
            </FilterList>
            <FilterList
                label="resources.contracts.fields.status"
                icon={<CheckIcon />}
            >
                <FilterListItem
                    label="ra.boolean.true"
                    value={{ status: true }}
                />
                <FilterListItem
                    label="ra.boolean.false"
                    value={{ status: false }}
                />
            </FilterList>
        </>
    );

    return (
        <Card>
            <CardContent>
                <CategoryFilter />
            </CardContent>
        </Card>
    );

};

export default AsideFilter;