import * as React from "react";
import {useState} from "react";
import {
    Button,
    Datagrid,
    DateField,
    DateInput as RaDateInput,
    Filter,
    List,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useGetOne
} from 'react-admin';
import {ContractAside} from "./ContractAside";
import BillListView from "../Bill/BillListView";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import {SectionTitle} from "../../../DepaComponents/Fields/SectionTitle";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import {
    DepaActionColStyles,
    DepaSingleSaveToolbar,
    DepaTextInput,
    DepaTooltip
} from "../../../DepaComponents/Components";
import {DateInput} from "../../../DepaComponents/DepaDatePicker/DepaDatePicker";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/core/styles";
import {Fab, Grid, Tooltip, Zoom} from "@material-ui/core";
import {ContractTitle} from "./edit";
import {default as handleCreate} from "../../../DepaComponents/DepaCreateHandler";
import {Link} from "react-router-dom";
import {stringify} from 'query-string';
import TaskEdit from "../Task/TaskEdit";
import QueueIcon from '@material-ui/icons/Queue';
import {DeleteRowButton} from "@react-admin/ra-editable-datagrid";
import {getUsername} from "../../../AuthProvider/authProvider";
import PrintIcon from "@material-ui/icons/Print";

const useStyles = makeStyles(theme => ({
    field: {
        display: "none"
    },
    list: {
        flexGrow: 1,
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 332,
    },
    drawerPaper: {
        zIndex: 100,
        minWidth: 332,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: 332,
            paddingTop: 55
        },
    },
    statusField: {
        alignContent: 'center',
        height: '50%',
        justifyContent: 'center',
        paddingTop: 20,
    },
    button: {
        marginBottom: 20,
    }
}));


const ContractShow = (props) => {
    const [createPanel, setCreatePanel] = useState(false);
    const classes = useStyles();
    const rowClasses = DepaActionColStyles();

    const CloneButton = (props) => {
        const {
            basePath = '',
            label = 'ra.action.clone',
            scrollToTop = true,
            record,
            icon,
            ...rest
        } = props;
        return (
            <Button
                component={Link}
                to={
                    record
                        ? {
                            search: stringify({
                                source: JSON.stringify(omitId(record), omitData(record)),
                            }),
                            state: {_scrollToTop: scrollToTop},
                        }
                        : null
                }
                label={label}
                onClick={handleClick}
                {...rest}
            >
                <QueueIcon/>
            </Button>
        );
    };
    // useful to prevent click bubbling in a datagrid with rowClick
    // const stopPropagation = e => e.stopPropagation() && handleClick();

    const omitId = ({id, taskDate, ...rest}) => rest;

    const omitData = (object) => (
        object['@id'] = null
    );

    const handleClick = () => {
        setCreatePanel(true);
    }

    const handleCloseClick = () => {
        setCreatePanel(false);
    };

    const DateRangeFilter = (props) => (
        <Filter {...props}>
            <RaDateInput
                source="taskDate[after]"
                label="Von"
                alwaysOn
            />
            <RaDateInput
                source="taskDate[before]"
                label="Bis"
                alwaysOn
            />
        </Filter>
    );

    const PrintTaskPdfButton = (props) => {
        const {filterValues} = props;
        return <DepaTooltip title="Abrechnungen drucken" aria-label="add" arrow TransitionComponent={Zoom}>
            <Button
                //if taskDate['after'] and taskDate[before] is set, the filterValues are not empty
                disabled={!(filterValues.taskDate && filterValues.taskDate.after && filterValues.taskDate.before)}
                label="Abrechnungen drucken"
                onClick={() => {
                    PostPdfData(props).then(res => {
                        //open window with pdf
                        window.open(res.url, '_blank');
                    })
                }}
            >
                <PrintIcon/>
            </Button>
        </DepaTooltip>
    };

    const PostPdfData = async (record) => {

        //url from .env
        const url = process.env.REACT_APP_SERVICE_TASK_PDF_URL;

        //wenn record.filterValues enthält taskDate
        if (record?.filterValues.taskDate) {
            const bevor = record?.filterValues.taskDate['before'];
            const after = record?.filterValues.taskDate['after'];
            const contract = record.props.id;

            //appaend date to url
            const urlWithDate = url + '?end=' + bevor + '&start=' + after + '&contract=' + contract;

            const response = await fetch(urlWithDate, {
                method: 'GET',
            });

            return response;
        } else return null;

    };
    const FilterDefaultValues = (props) => {
        let {data, loading} = useGetOne(props.resource, props.id)
        //if (loading) return null;
        //wait for data to be loaded
        if (loading) return null;
        //if data is loaded
        if (data) {
            return {
                taskDate: {
                    after: data.cycleStartDate,
                    before: data.cycleEndDate
                }
            }
        }

    };

    const TaskListActions = (props) => {
        return (
            <TopToolbar>
                <PrintTaskPdfButton props={props}/>
                <Tooltip title="Neuer Eintrag" aria-label="add" arrow TransitionComponent={Zoom}>
                    <Fab color="primary" className="ms-4" aria-label="add" onClick={handleClick}
                         label={'ra.action.create'}>
                        <AddIcon/>
                    </Fab>
                </Tooltip>
            </TopToolbar>
        )
    };

    return <Show {...props}
                 title={<ContractTitle/>}
                 aside={createPanel === false ? <ContractAside link={"show"}/> : null}
                 hasEdit={false}
                 className={(classes.list, {
                     [classes.listWithDrawer]: createPanel,
                 })}>
        <TabbedShowLayout>
            <Tab label="Abrechnungen">
                <Grid container spacing={3} xs={12} fullWidth>
                    <Grid item xs={12}>

                        <List

                            style={{
                                marginTop: '40px',
                            }}
                            title={' '}
                            resource={'service_tasks'}
                            basePath={'/service_tasks'}
                            exporter={false}
                            empty={false}
                            hasCreate={false}
                            actions={
                                <PrintTaskPdfButton props={props}/>
                            }
                            filter={{serviceContract: props.id}}
                            hasEdit={false}
                            sort={{field: 'taskDate', order: 'DESC'}}
                            filters={<DateRangeFilter props={props}/>}
                            perPage={10}>
                            <Datagrid>
                                <DateField source={'taskDate'}
                                           label={'resources.service_task.fields.task_date'}/>
                                <TextField source={'minutes'} label={'resources.service_task.fields.minutes'}/>
                                <TextField source={'dynamicDescription'}
                                           label={'resources.service_task.fields.description'}/>

                                <ReferenceField source="serviceContractRate" reference="service_contract_rates"
                                                label={'resources.contracts.fields.contract_rate'} fullWidth
                                                linkType={false}
                                >
                                    <TextField optionText="identifier" source={'identifier'}/>
                                </ReferenceField>
                                <TextField source={'taskAuthor'} label={'resources.service_task.fields.task_author'}/>

                                <TaskEdit cellClassName={rowClasses.depaActionCol}/>
                                {/*  <CloneButton label={""} cellClassName={rowClasses.depaActionCol}/>*/}
                                <DeleteRowButton redirect={''} undoable={true}
                                                 cellClassName={rowClasses.depaActionCol}/>
                            </Datagrid>
                        </List>
                    </Grid>
                    <Grid item xs={12} className={classes.button}>
                        <Tooltip title="Neuer Eintrag" aria-label="add" arrow TransitionComponent={Zoom}>
                            <Fab color="primary" className="ms-4" aria-label="add" onClick={handleClick}
                                 label={'ra.action.create'}>
                                <AddIcon/>
                            </Fab>
                        </Tooltip>
                    </Grid>
                </Grid>

                <Drawer anchor="right" open={createPanel} className={classes.drawerPaper}
                        variant="persistent" onClose={handleCloseClick}>
                    <div>
                        <Button label="ra.action.cancel" onClick={handleCloseClick}>
                            <CloseIcon/>
                        </Button>
                    </div>
                    <SimpleForm
                        save={handleCreate('service_tasks', setCreatePanel)}
                        resource={'service_tasks'}
                        title={' '}
                        toolbar={<DepaSingleSaveToolbar listButton={false}/>}

                    >
                        <SectionTitle label={'resources.service_task.create'}/>
                        <DateInput source="taskDate" label={'resources.service_task.fields.task_date'}
                                   validate={required()} fullWidth
                                   options={{format: "d MMM yyyy", clearable: true}}
                                   providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                        <NumberInput source={'minutes'} validate={required()}
                                     label={'resources.service_task.fields.minutes'} fullWidth
                                     min={0}/>
                        <ReferenceInput reference={'service_contract_rates'} source={'serviceContractRate'}
                                        label={'resources.service_task.fields.service_contract_rate'} fullWidth
                                        filter={{serviceContract: props.id}}
                                        validate={required()}>
                            <SelectInput optionText={'identifier'}/>
                        </ReferenceInput>
                        <ReferenceInput reference={'service_task_templates'} source={'serviceTaskTemplates'}
                                        label={'resources.service_task.fields.service_task_templates'}
                                        resettable
                                        fullWidth>
                            <SelectInput optionText={'identifier'}/>
                        </ReferenceInput>
                        <DepaTextInput source={'description'}
                                       label={'resources.service_task.fields.description'}
                                       rows={3}
                                       multiline/>
                        <DepaTextInput source={'comment'} label={'resources.service_task.fields.comment'}
                                       rows={3}
                                       multiline/>
                        <TextInput source={'serviceContract'}
                                   defaultValue={props.id}
                                   className={classes.field}
                        />
                        <TextInput source={'taskAuthor'}
                                   label={'Autor'}
                                   defaultValue={getUsername()}
                                   disabled
                                   fullWidth
                                   className={classes.field}
                        />
                    </SimpleForm>
                </Drawer>
            </Tab>
            <Tab label="Rechnungen">
                < Grid container={true} spacing={3} xs={12} fullWidth>
                    <Grid xs={12} item>
                        <BillListView/>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Tooltip title="Neuer Eintrag" aria-label="add" arrow TransitionComponent={Zoom}>
                                <Fab color="primary" className="ms-4" aria-label="add" onClick={handleClick}
                                     label={'ra.action.create'}>
                                    <AddIcon/>
                                </Fab>
                            </Tooltip>

                        </Grid>
                    </Grid>
                </Grid>
                <Drawer anchor="right" open={createPanel} className={classes.drawerPaper}

                        variant="persistent" onClose={handleCloseClick}>
                    <div>
                        <Button label="ra.action.cancel" onClick={handleCloseClick}>
                            <CloseIcon/>
                        </Button>
                    </div>
                    <SimpleForm
                        save={handleCreate('service_bills', setCreatePanel)}
                        resource={'service_bills'}
                        toolbar={<DepaSingleSaveToolbar listButton={false}/>}
                    >
                        <SectionTitle label={'resources.service_bills.create'}/>
                        <DateInput source="billDate" label={'resources.service_bills.fields.bill_date'}
                                   fullWidth
                                   options={{format: "d MMM yyyy", clearable: true}}
                                   providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                        <NumberInput source={'minutes'} label={'resources.service_bills.fields.minutes'}
                                     fullWidth
                                     validate={required()}/>
                        <TextInput source={'serviceContract'}
                                   validate={required()}
                                   defaultValue={props.id}
                                   className={classes.field}/>
                    </SimpleForm>
                </Drawer>
            </Tab>
        </TabbedShowLayout>
    </Show>
};


export default ContractShow;