import * as React from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import {Box, useMediaQuery} from '@material-ui/core';
import {DashboardMenuItem, MenuItemLink, useTranslate,} from 'react-admin';
import GroupIcon from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
import LanguageIcon from '@material-ui/icons/Language';
import DescriptionIcon from '@material-ui/icons/Description';
import CategoryIcon from '@material-ui/icons/Category';
import CollectionsIcon from '@material-ui/icons/Collections';
import SubMenu from './SubMenu';


const Menu = ({onMenuClick, logout, dense = false}) => {
    const [state, setState] = useState({
        menuContract: true,
        menuCustomers: true,
        menuConfig: true
    });
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({...state, [menu]: !state[menu]}));
    };

    return (
        <Box mt={1}>
            {' '}
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open}/>
            <MenuItemLink
                to={`/service_contracts`}
                primaryText={translate(`resources.contracts.name`, {
                    smart_count: 2,
                })}
                leftIcon={<DescriptionIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <SubMenu
                handleToggle={() => handleToggle('menuCustomers')}
                isOpen={state.menuCustomers}
                sidebarIsOpen={open}
                name="menu.crm"
                icon={< GroupIcon/>}
                dense={dense}
            >
                <MenuItemLink
                    to={`/crm_contacts`}
                    primaryText={translate(`resources.customers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<GroupIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/crm_companies`}
                    primaryText={translate(`resources.company.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<BusinessIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/crm_countries`}
                    primaryText={translate(`resources.country.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LanguageIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuConfig')}
                isOpen={state.menuConfig}
                sidebarIsOpen={open}
                name="menu.config"
                icon={< SettingsIcon/>}
                dense={dense}
            >
                <MenuItemLink
                    to={`/service_contract_types`}
                    primaryText={translate(`resources.contract_type.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<CategoryIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/service_task_templates`}
                    primaryText={translate(`resources.task_templates.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<CollectionsIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                {/* <MenuItemLink
                    to={`/service_contract_rates`}
                    primaryText={translate(`resources.service_contract_rate.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<InsertChartIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />*/}
            </SubMenu>
            {isXSmall && (
                <MenuItemLink
                    to="/configuration"
                    primaryText={translate('resources.config.name')}
                    leftIcon={<SettingsIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXSmall && logout}
        </Box>
    );
};

export default Menu;