import React from "react";
import {Edit, DepaTextInput} from "../../../DepaComponents/Components";
import {SimpleForm} from "react-admin";

const CountryEdit = (props) => (
    <Edit {...props} title="Land erstellen">
        <SimpleForm onSubmit={props.save} redirect={'list'}>
            <DepaTextInput source="identifier"/>
            <DepaTextInput source="name"/>
            <DepaTextInput source="countryCode"/>
        </SimpleForm>
    </Edit>
);
export default CountryEdit;