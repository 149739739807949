import React from 'react';
import { Edit as ReactAdminEdit } from '@react-admin/ra-enterprise';
import {EditActions} from './EditActions';

const Edit = ReactAdminEdit;

Edit.defaultProps = {
    actions: <EditActions />,
};

export default Edit;