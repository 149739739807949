//import { default as ServiceTaskShow } from './show';
import { default as  ServiceTaskCreate } from './create';
import { default as  ServiceTaskList } from './list';
import { default as  ServiceTaskEdit } from './edit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: ServiceTaskList,
    create: ServiceTaskCreate,
    edit: ServiceTaskEdit
    //show: ServiceTaskShow
};