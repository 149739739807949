import React from "react";
import {
    ReferenceInput,
    SimpleForm,
    useTranslate,
    required, SelectInput
} from "react-admin";
import {Create, DepaNumberInput,} from "../../../DepaComponents/Components";
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";
import {DateInput} from "../../../DepaComponents/DepaDatePicker/DepaDatePicker";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";


const SectionTitle = ({label}) => {
    const translate = useTranslate();
    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};
const ContactCreate = (props) => (
    <Create {...props} title="resources.service_bills.create">
        <SimpleForm onSubmit={props.save} redirect={'list'}>
            <Grid container spacing={1} xs={12} fullWidth>
                <Grid item xs={12}>
                    <SectionTitle label={'Rechnung'}/>
                </Grid>
                <DepaNumberInput xs={12} md={6} source={'minutes'} label={'resources.service_bills.fields.minutes'} validate={required()}/>
                <Grid item xs={12} md={6}>
                <DateInput source="billDate" label={'resources.service_bills.fields.bill_date'} fullWidth
                           options={{format: "d MMM yyyy", clearable: true}}
                           providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                </Grid>
                <ReferenceInput reference={'service_contracts'} source={'serviceContract'} label={'resources.service_bills.fields.service_contract'}  fullWidth  validate={required()}>
                    <SelectInput optionText={'identifier'}/>
                </ReferenceInput>
            </Grid>
        </SimpleForm>
    </Create>
);
export default ContactCreate;