import {default as ApiGroupList} from './list';
import {default as ApiGroupCreate} from './create';
import {default as ApiGroupEdit} from './edit';
//import { default as ApiGroupShow } from './show';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: ApiGroupList,
    create: ApiGroupCreate,
    edit: ApiGroupEdit
    //show: ApiGroupShow
};