import {Datagrid, DateField, Pagination, ReferenceManyField, TextField, useTranslate} from "react-admin";
import {CommentFileView} from "./CommentFileView";
import React from "react";
import {Card, CardContent} from "@material-ui/core";
import {DepaActionColStyles} from "../../../DepaComponents/DepaActionColStyles";
import {DeleteRowButton,} from "@react-admin/ra-editable-datagrid";
import CommentEdit from "./CommentEdit";


const CommentPanel = ({record}) => {
    const translate = useTranslate();
    return <Card title={'resources.service_contract_comments.fields.comments'}>
        <CardContent> {translate('resources.service_contract_comments.fields.comments')}: </CardContent>
        <CardContent dangerouslySetInnerHTML={{__html: record.comment}}/>
    </Card>
};

const CommentListView = (props) => {
    const rowStyle = DepaActionColStyles();
    return <ReferenceManyField reference="service_contract_comments"
                               target='serviceContract'
                               sort={{field: 'commentDate', order: 'DESC'}}
                               pagination={<Pagination/>}
                               perPage={10}
    >
        <Datagrid
            optimized
        >
            <TextField source={'comment'}
                       label={'resources.service_contract_comments.fields.comments'}/>
            <DateField source={'commentDate'}
                       label={'resources.service_contract_comments.fields.comment_date'}/>

            <CommentEdit cellClassName={rowStyle.depaActionCol}/>
            <CommentFileView cellClassName={rowStyle.depaActionCol}/>
            <DeleteRowButton redirect={''} undoable={true}
                             cellClassName={rowStyle.depaActionCol}/>
        </Datagrid>
    </ReferenceManyField>
}

export default CommentListView;