import React from "react";
import {
    required,
    SimpleForm, TextInput,
    useTranslate,
} from "react-admin";
import {Create, DepaTextInput,} from "../../../DepaComponents/Components";
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";


const SectionTitle = ({label}) => {
    const translate = useTranslate();
    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};
const ContractRateCreate = (props) => (
    <Create {...props} title="resources.service_contract_rate.create">
        <SimpleForm onSubmit={props.save} redirect={'list'}>
            <Grid container spacing={1} xs={12} fullWidth>
                <Grid item xs={12}>
                    <SectionTitle label={'Vertragsrate'}/>
                </Grid>
                <DepaTextInput xs={12} md={4} source={'identifier'} label={'resources.service_contract_rate.fields.identifier'}
                               validate={required()}/>
                <Grid item xs={12}>
                <TextInput
                    resettable
                    fullWidth xs={12}
                    source={'hourlyRate'}
                    label={'resources.service_contract_rate.fields.hourly_rate'}/>
                </Grid>
                <Grid item xs={12}>
                <TextInput
                    resettable
                    fullWidth xs={12}
                           source={'hourlyRateIncrease'}
                           label={'resources.service_contract_rate.fields.hourly_rate_increase'}/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
export default ContractRateCreate;