import React from "react";
import {Breadcrumb, ResourceBreadcrumbItems} from "@react-admin/ra-navigation";
import { ShowButton, ListButton, TopToolbar } from "react-admin";

const MyBreadcrumb = (props) => (
    <Breadcrumb {...props}>
        <ResourceBreadcrumbItems />
    </Breadcrumb>
);

export const EditActions = ({
                                basePath,
                                className,
                                data,
                                hasList,
                                hasShow,
                                resource,
                            }) => (
                                <>
    <TopToolbar className={className}>
        <MyBreadcrumb variant="actions" />
        {hasList && <ListButton basePath={basePath} />}
        {hasShow && <ShowButton basePath={basePath} record={data} />}
    </TopToolbar>
                                </>

)