import React, {useState} from "react";
import {IconButton} from '@material-ui/core';
import {useUpdate, useNotify} from "react-admin";
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import {DepaTooltip} from "../Components";

function DepaVisibleButton(props) {
    const [hiddenState, setHiddenState] = useState(props.record.hidden);
    const notify = useNotify();
    const diff = {hidden: !props.record.hidden};
    const [update, {loading}] = useUpdate();
    //Sorgt dafür das in der Liste der Korrekte Status angezeigt wird ohne neu laden
    const flipHiddenState = () => {
        setHiddenState(!hiddenState);
    };
    const handleClick = () => {
        update(
            {resource : props.resource,
                payload: {id: props.record.id, data: diff, record: props.record }},
            {
                onSuccess: ({data}) => {
                    flipHiddenState()
                    if (hiddenState === true){
                        notify('Inhalt wird jetzt angezeigt')
                    }
                    else {
                        notify('Inhalt ist jetzt versteckt' )
                    }
                },
                onFailure: (error) => {
                    notify(`Fehler: ${error.message}`, 'warning');
                }
            }
        )
    }
    return (
        <DepaTooltip title={'Sichtbarkeit'}>
            <div>
                <IconButton disabled={loading} onClick={function(event){ handleClick(); event.stopPropagation();}} >
                    {hiddenState
                        ? <EmojiObjectsOutlinedIcon/>
                        : <EmojiObjectsIcon color={'primary'}/>
                    }
                </IconButton>
            </div>
        </DepaTooltip>

    )
}

export default DepaVisibleButton;