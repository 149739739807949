import {CreateActions as RaCreateActions} from '@react-admin/ra-enterprise';
import React from "react";
import {Breadcrumb, ResourceBreadcrumbItems} from "@react-admin/ra-navigation";

const MyBreadcrumb = (props) => (
    <Breadcrumb {...props}>
        <ResourceBreadcrumbItems />
    </Breadcrumb>
);

export const CreateActions = (props) => (<RaCreateActions {...props} breadcrumb={<MyBreadcrumb variant="actions" />} />)