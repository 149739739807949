import {default as ApiUserList} from './list';
import {default as ApiUserCreate} from './create';
import {default as ApiUserEdit} from './edit';
//import { default as ApiUserShow } from './show';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: ApiUserList,
    create: ApiUserCreate,
    edit: ApiUserEdit,
    //show: ApiUserShow
};