import {useCreate, useNotify, useRefresh} from "react-admin";


function DepaCreateHandler(resource, setState) {
    const [create] = useCreate(resource);
    const notify = useNotify();
    const refresh = useRefresh();
    return async props => {
        create(
            {payload: {data: props}},
            {
                onSuccess: ({update}) => {
                    setState(false);
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    refresh();
                },
                onFailure: ({error}) => {
                    notify(error.message, 'error');
                }
            }
        );
    }
}

export default DepaCreateHandler;