import React from "react";
import {Grid} from "@material-ui/core";
import {DepaDialogEditButton, DepaTextInput} from "../../../DepaComponents/Components";
import {required, TextInput} from "react-admin";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ContractRateEdit = (props) => {
    return <DepaDialogEditButton {...props} title={'Vertragsrate Bearbeiten'}
                                 resource={'service_contract_rates'}
                                 label={''} enterNextDelay={500}>
        <Grid container spacing={1} xs={12} fullWidth>
            <DepaTextInput xs={12} md={6} source={'identifier'}
                           label={'resources.service_contract_rate.fields.identifier'}
                           validate={required()}/>
            <Grid item xs={12}>
                <TextInput
                    resettable
                    fullWidth xs={12}
                    source={'hourlyRate'}
                    label={'resources.service_contract_rate.fields.hourly_rate'}/>
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    resettable
                    fullWidth xs={12}
                    source={'hourlyRateIncrease'}
                    label={'resources.service_contract_rate.fields.hourly_rate_increase'}/>
            </Grid>
        </Grid>
    </DepaDialogEditButton>
}

export default ContractRateEdit;