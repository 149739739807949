import React from 'react';
import {TabbedForm as RaTabbedForm} from "react-admin";
import DefaultToolbar from "./DefaultToolbar";

const DepaTabbedForm = RaTabbedForm;

DepaTabbedForm.defaultProps = {
    toolbar: <DefaultToolbar/>
};

export default DepaTabbedForm;