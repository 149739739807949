import React, {useState} from 'react';
import {Button, FormWithRedirect, SaveButton, useCreate, useNotify, useRefresh} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles({
    appBar: {
        //paddingBottom: 20
    },
    button: {
        marginTop: 20
    }
});

function DepaDialogCreateButton(props) {
    const [creteShowDialog, setCreateShowDialog] = useState(false);
    const [create, {loading}] = useCreate(props.resource);
    const notify = useNotify();
    const refresh = useRefresh();
    const classes = useStyles();

    const handleClick = () => {
        setCreateShowDialog(true);
    };

    const handleCreateCloseClick = () => {
        setCreateShowDialog(false);
    };
    const handleSubmit = async values => {
        create(
            {payload: {data: values}},
            {
                onSuccess: ({data}) => {
                    setCreateShowDialog(false);
                    //refresh page after Create
                    notify(`Element wurde erstellt`, undefined, undefined, false);
                    refresh();
                },
                onFailure: (error) => {
                    notify(`Fehler: ${error.message}`, 'warning');
                }
            }
        );
    };

    return (
        <>
            <Button onClick={handleClick} disabled={props.disabled} label={"ra.action.create"}
                    className={classes.button}>
                {props.icon}
            </Button>
            <Dialog
                fullWidth={props.fullWidth}
                fullScreen={props.fullScreen}
                maxWidth={props.maxWidth}
                open={creteShowDialog}
                onClose={handleCreateCloseClick}
                aria-label={'Erstellen'}

            >
                <DialogTitle>{props.title}</DialogTitle>

                <FormWithRedirect
                    resource={props.resource}
                    save={handleSubmit}
                    render={({
                                 handleSubmitWithRedirect,
                                 pristine,
                                 saving
                             }) => (
                        <>
                            {props.fullScreen === true ? <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleCreateCloseClick}
                                                aria-label="close">
                                        <CloseIcon/>
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        {props.title}
                                    </Typography>


                                </Toolbar>
                            </AppBar> : false}
                            <DialogContent>
                                {props.children}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCreateCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel/>
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

DepaDialogCreateButton.defaultProps = {
    resource: false,
    record: false,
    maxWidth: false,
    fullScreen: false,
    fullWidth: false,
    title: "Erstellen",
    icon: <IconContentAdd/>
}

export default (DepaDialogCreateButton);