import React from "react";
import {
    ReferenceInput,
    SimpleForm,
    useTranslate,
    required, SelectInput, NumberInput
} from "react-admin";
import {Create, DepaTextInput} from "../../../DepaComponents/Components";
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";
import {AccordionSection} from "@react-admin/ra-form-layout";
import {DateInput} from "../../../DepaComponents/DepaDatePicker/DepaDatePicker";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";

const SectionTitle = ({label}) => {
    const translate = useTranslate();
    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};
const ContactCreate = (props) => (
    <Create {...props} title="resources.service_task.create">
        <SimpleForm onSubmit={props.save} redirect={'list'}>
            <Grid container spacing={1} xs={12} fullWidth>
                <Grid item xs={12}>
                    <SectionTitle label={'resources.service_task.form_name'}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <DateInput source="taskDate" label={'resources.service_task.fields.task_date'}
                               validate={required()} fullWidth
                               options={{format: "d MMM yyyy", clearable: true}}
                               providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <NumberInput source={'minutes'} validate={required()}
                                 label={'resources.service_task.fields.minutes'} fullWidth
                                 min={0}/>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput reference={'service_contracts'} source={'serviceContract'}
                                    label={'resources.service_bills.fields.service_contract'} fullWidth
                                    validate={required()}>
                        <SelectInput optionText={'identifier'}/>
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput reference={'service_contract_rates'} source={'serviceContractRate'}
                                    label={'resources.service_task.fields.service_contract_rate'} fullWidth
                                    validate={required()}>
                        <SelectInput optionText={'identifier'}/>
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput reference={'service_task_templates'} source={'serviceTaskTemplates'}
                                    label={'resources.service_task.fields.service_task_templates'} fullWidth>
                        <SelectInput optionText={'identifier'}/>
                    </ReferenceInput>
                </Grid>
            </Grid>
            <AccordionSection label="Beschreibung" fullWidth>
                <DepaTextInput source={'description'} label={''} rows={3} multiline/>
            </AccordionSection>
            <AccordionSection label="Kommentar" fullWidth>
                <DepaTextInput source={'comment'} label={''} rows={3} multiline/>
            </AccordionSection>
        </SimpleForm>
    </Create>
);
export default ContactCreate;