import {ListButton, SaveButton, Toolbar} from "react-admin";
import React from "react";

const DepaSingleSaveToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
        {props?.listButton ? <ListButton basePath={props.basePath} label={'Liste'}
                                         style={{marginLeft: 'auto'}}/> : null}
    </Toolbar>
);

export default DepaSingleSaveToolbar;