import {Login} from 'react-admin';
import {lightTheme} from "./Theme";
//import login from "../Media/login.png"

const CustomLoginPage = () => (
    <Login
        //className={'Depa'}
        // backgroundImage={login}
        title="login"
        theme={lightTheme()}

    />
);

export default CustomLoginPage;