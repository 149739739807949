import {NumberInput} from "react-admin";
import {Grid} from "@material-ui/core";
import React from "react";

function DepaNumberInput(props) {
    return (
        <Grid xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} item={props.item}>
            <NumberInput source={props.source}
                         label={props.label}
                         resettable={props.resettable}
                         fullWidth={props.fullWidth}
                         validate={props.validate}
                         defaultValue={props.defaultValue}
                         defaultChecked={props.defaultChecked}
            />
        </Grid>
    )
}

DepaNumberInput.defaultProps = {
    xl: false,
    lg: false,
    md: false,
    sm: false,
    xs: false,
    resettable: true,
    item: true,
    fullWidth: true
}

export default DepaNumberInput;