import React, {useState} from "react";
import {
    BooleanInput,
    Button,
    FormTab,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TabbedForm,
    TextInput,
    Toolbar
} from "react-admin";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import {DepaNumberInput, DepaTextInput, Edit} from "../../../DepaComponents/Components";
import {DateInput} from "../../../DepaComponents/DepaDatePicker/DepaDatePicker";
import {makeStyles} from "@material-ui/core/styles";
import {Divider, Grid} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import {SectionTitle} from "../../../DepaComponents/Fields/SectionTitle";
import {default as handleCreate} from "../../../DepaComponents/DepaCreateHandler";
import CommentListView from "../Comments /CommentListView";
import ContractRateListView from "../ContractRate/ContractRateListView";
import {ContractAside} from "./ContractAside";

const useStyles = makeStyles(theme => ({
    field: {
        display: "none"
    },
    list: {
        flexGrow: 1,
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
        minWidth: 400,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: 400,
            paddingTop: 55
        },
    },
    statusField: {
        alignContent: 'center',
        height: '50%',
        justifyContent: 'center',
        paddingTop: 20,
    }
}));


const CreateToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);

export const FullNameField = ({record}) => {
    if (record !== undefined && record.firstName !== undefined && record.crmCompany !== undefined) {
        return <span> {record.surName} {record.firstName} ({record.crmCompany.name})</span>
    } else if (record?.crmCompany === undefined && record?.firstName === undefined) {
        return <span> {record?.surName}</span>
    } else if (record?.firstName === undefined) {
        return <span> {record?.surName} ({record?.crmCompany.name})</span>
    } else if (record?.crmCompany === undefined) {
        return <span> {record?.surName} {record?.firstName}</span>
    }
};

export const ContractTitle = ({record}) =>
    record ? <span>'{record.identifier}' bearbeiten</span> : null;


const ContactEdit = (props, {link = "edit"}) => {
    const [createPanel, setCreatePanel] = useState(false);
    const classes = useStyles();

    const handleClick = () => {
        setCreatePanel(true);
    }

    const handleCloseClick = () => {
        setCreatePanel(false);
    };


    return (
        <Edit {...props} title={<ContractTitle/>}
              actions={''}
              className={(classes.list, {
                  [classes.listWithDrawer]: createPanel,
              })}
              aside={createPanel === false ? <ContractAside link={"edit"}/> : null}
        >
            <TabbedForm onSubmit={props.save} syncWithLocation={true} redirect={'list'}>
                <FormTab label={'Inhalt'} fullWidth>
                    <Grid container spacing={2} xs={12} fullWidth>
                        <DepaTextInput source={'identifier'} xs={10}
                                       label={'resources.contracts.fields.identifier'}
                                       validate={required()}/>
                        <Grid item xs={2}>
                            <BooleanInput source="status" className={classes.statusField}
                                          label={'resources.contracts.fields.status'}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ReferenceInput source="crmContact" reference="crm_contacts"
                                            label={'resources.contracts.fields.crm_contact'}
                                            validate={required()} fullWidth
                                            perPage={100} sort={{field: 'surName', order: 'ASC'}}
                                            format={v => v !== null && typeof v === 'object' && v.hasOwnProperty('@id') ? v['@id'] : v}>
                                <SelectInput optionText={<FullNameField/>} source={'surName'}/>
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ReferenceInput source="serviceContractType" reference="service_contract_types"
                                            label={'resources.contracts.fields.service_contract_type'} fullWidth
                                            validate={required()}
                                            format={v => v !== null && typeof v === 'object' && v.hasOwnProperty('@id') ? v['@id'] : v}>
                                <SelectInput optionText="identifier"/>
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant={'fullWidth'}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DateInput source="dateStart" label={'resources.contracts.fields.date_start'} fullWidth
                                       options={{format: "d MMM yyyy", clearable: true}}
                                       providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DateInput source="dateEnd" label={'resources.contracts.fields.date_end'} fullWidth
                                       options={{format: "d MMM yyyy", clearable: true}}
                                       providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant={'fullWidth'}/>
                        </Grid>
                        <DepaNumberInput xs={12} md={6} source="hourPerMonth" min="0"
                                         label={'resources.contracts.fields.hoursPerMonth'}/>
                        <DepaNumberInput xs={12} md={6} source="monthMin" min="0"
                                         label={'resources.contracts.fields.monthMin'}/>
                        <DepaNumberInput xs={12} md={6} source="noticePeriod"
                                         label={'resources.contracts.fields.noticePeriod'}/>
                        <DepaTextInput xs={12} md={6} source="billingCycle"
                                       label={'resources.contracts.fields.billingCycle'}/>
                    </Grid>
                </FormTab>
                <FormTab label={'resources.contracts.fields.comments'} path={'/comments'}>
                    <Grid container spacing={2} xs={12} fullWidth>
                        <DepaTextInput fullWidth xs={12} source="comments"
                                       label={'resources.contracts.fields.comments'}
                                       rows={3}
                                       multiline/>

                        <Grid item xs={12}>
                            <CommentListView/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={handleClick} label={'ra.action.create'}>
                                <AddIcon/>
                            </Button>
                        </Grid>
                    </Grid>
                    <Drawer anchor="right" open={createPanel} className={classes.drawerPaper}

                            variant="persistent" onClose={handleCloseClick}>
                        <div>
                            <Button label="ra.action.cancel" onClick={handleCloseClick}>
                                <CloseIcon/>
                            </Button>
                        </div>
                        <SimpleForm
                            toolbar={<CreateToolbar/>}
                            save={handleCreate('service_contract_comments', setCreatePanel)}
                            resource={'service_contract_comments'}

                        >
                            <SectionTitle label={'resources.service_contract_comments.create'}/>
                            {/* <DepaTextInput source={'identifier'}
                                           label={'resources.service_contract_comments.fields.identifier'}/>*/}
                            <DateInput source="commentDate"
                                       label={'resources.service_contract_comments.fields.comment_date'} fullWidth
                                       options={{format: "d MMM yyyy", clearable: true}}
                                       providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                            <DepaTextInput source={'comment'}
                                           label={'resources.service_contract_comments.fields.comments'} multiline
                                           rows={4}/>
                            <TextInput source={'serviceContract'}
                                       defaultValue={props.id}
                                       className={classes.field}/>
                        </SimpleForm>
                    </Drawer>

                </FormTab>
                {/*                <FormTab label={'resources.service_bills.form_name'} path={'/bills'}>
                    <Grid container={true} spacing={3} xs={12} fullWidth>
                        <Grid xs={12} item>
                            <BillListView/>
                            <Grid item xs={12}>
                                <Button onClick={handleClick} label={'ra.action.create'}>
                                    <AddIcon/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Drawer anchor="right" open={createPanel} className={classes.drawerPaper}

                            variant="persistent" onClose={handleCloseClick}>
                        <div>
                            <Button label="ra.action.cancel" onClick={handleCloseClick}>
                                <CloseIcon/>
                            </Button>
                        </div>
                        <SimpleForm
                            toolbar={<CreateToolbar/>}
                            save={handleCreate('service_bills', setCreatePanel)}
                            resource={'service_bills'}

                        >
                            <SectionTitle label={'resources.service_bills.create'}/>
                            <DateInput source="billDate" label={'resources.service_bills.fields.bill_date'}
                                       fullWidth
                                       options={{format: "d MMM yyyy", clearable: true}}
                                       providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                            <NumberInput source={'minutes'} label={'resources.service_bills.fields.minutes'}
                                         fullWidth
                                         validate={required()}/>
                            <TextInput source={'serviceContract'}
                                       validate={required()}
                                       defaultValue={props.id}
                                       className={classes.field}/>
                        </SimpleForm>
                    </Drawer>
                </FormTab>
                <FormTab label={'Abrechnungen'} path={'/tasks'}>
                    <Grid container={true} spacing={3} xs={12} fullWidth>
                        <Grid xs={12} item>
                            <ServiceTaskListView/>
                            <Button onClick={handleClick} label={'ra.action.create'}>
                                <AddIcon/>
                            </Button>
                        </Grid>
                    </Grid>
                    <Drawer anchor="right" open={createPanel} className={classes.drawerPaper}

                            variant="persistent" onClose={handleCloseClick}>
                        <div>
                            <Button label="ra.action.cancel" onClick={handleCloseClick}>
                                <CloseIcon/>
                            </Button>
                        </div>
                        <SimpleForm
                            toolbar={<CreateToolbar/>}
                            save={handleCreate('service_tasks', setCreatePanel)}
                            resource={'service_tasks'}

                        >
                            <SectionTitle label={'resources.service_task.create'}/>
                            <DateInput source="taskDate" label={'resources.service_task.fields.task_date'}
                                       validate={required()} fullWidth
                                       options={{format: "d MMM yyyy", clearable: true}}
                                       providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                            <NumberInput source={'minutes'} validate={required()}
                                         label={'resources.service_task.fields.minutes'} fullWidth
                                         min={0}/>
                            <ReferenceInput reference={'service_contract_rates'} source={'serviceContractRate'}
                                            label={'resources.service_task.fields.service_contract_rate'} fullWidth
                                            filter={{serviceContract: props.id}}
                                            validate={required()}>
                                <SelectInput optionText={'identifier'}/>
                            </ReferenceInput>
                            <ReferenceInput reference={'service_task_templates'} source={'serviceTaskTemplates'}
                                            label={'resources.service_task.fields.service_task_templates'}
                                            resettable
                                            fullWidth>
                                <SelectInput optionText={'identifier'}/>
                            </ReferenceInput>
                            <DepaTextInput source={'description'}
                                           label={'resources.service_task.fields.description'}
                                           rows={3}
                                           multiline/>
                            <DepaTextInput source={'comment'} label={'resources.service_task.fields.comment'}
                                           rows={3}
                                           multiline/>
                            <TextInput source={'serviceContract'}
                                       defaultValue={props.id}
                                       className={classes.field}/>
                        </SimpleForm>
                    </Drawer>
                </FormTab>*/}
                <FormTab label={'Vertragsrate'} path={'/rates'}>
                    <ContractRateListView/>
                    <div>
                        <Button onClick={handleClick} label={'ra.action.create'}>
                            <AddIcon/>
                        </Button>
                    </div>
                    <Drawer anchor="right" open={createPanel} className={classes.drawerPaper}

                            variant="persistent" onClose={handleCloseClick}>
                        <div>
                            <Button label="ra.action.cancel" onClick={handleCloseClick}>
                                <CloseIcon/>
                            </Button>
                        </div>
                        <SimpleForm
                            toolbar={<CreateToolbar/>}
                            save={handleCreate('service_contract_rates', setCreatePanel)}
                            resource={'service_contract_rates'}

                        >
                            <SectionTitle label={'resources.service_contract_rate.create'}/>

                            <DepaTextInput source={'identifier'}
                                           label={'resources.service_contract_rate.fields.identifier'}
                                           validate={required()}/>

                            <TextInput
                                resettable
                                fullWidth xs={12}
                                source={'hourlyRate'}
                                label={'resources.service_contract_rate.fields.hourly_rate'}/>

                            <TextInput
                                resettable
                                fullWidth xs={12}
                                source={'hourlyRateIncrease'}
                                label={'resources.service_contract_rate.fields.hourly_rate_increase'}/>
                            <TextInput source={'serviceContract'}
                                       defaultValue={props.id}
                                       className={classes.field}/>
                        </SimpleForm>
                    </Drawer>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};
export default ContactEdit;