import {TextInput} from "react-admin";
import {Grid} from "@material-ui/core";
import React from "react";

function DepaTextInput(props) {
    return (
        <Grid xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} item={props.item}>
            <TextInput source={props.source}
                       label={props.label}
                       type={props.type}
                       resettable={props.resettable}
                       multiline={props.multiline}
                       fullWidth={props.fullWidth}
                       validate={props.validate}
                       defaultValue={props.defaultValue}
                       rows={props.rows}
            />
        </Grid>
    )
}

DepaTextInput.defaultProps = {
    xl: false,
    lg: false,
    md: false,
    sm: false,
    xs: false,
    resettable: true,
    item: true,
    fullWidth: true,
    type: "text",
    multiline: false,
    rows: 1
}

export default DepaTextInput;