import {Datagrid, DateField, Pagination, ReferenceManyField, TextField} from "react-admin";
import React from "react";
import {DeleteRowButton} from "@react-admin/ra-editable-datagrid";
import {DepaActionColStyles} from "../../../DepaComponents/DepaActionColStyles";
import BillEdit from "./BillEdit";

const BillListView = (props) => {
    const rowStyle = DepaActionColStyles();

    return <ReferenceManyField reference="service_bills" target='serviceContract'
                               sort={{field: 'billDate', order: 'DESC'}}
                               perPage={10}
                               pagination={<Pagination/>}
    >
        <Datagrid
            optimized
        >
            <DateField source="billDate" label={'resources.service_bills.fields.bill_date'}
                       fullWidth
            />
            <TextField source={'minutes'} label={'resources.service_bills.fields.minutes'}
            />
            <BillEdit cellClassName={rowStyle.depaActionCol}/>
            <DeleteRowButton redirect={''} undoable={true}
                             cellClassName={rowStyle.depaActionCol}/>
        </Datagrid>
    </ReferenceManyField>
}

export default BillListView;