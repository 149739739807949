import {makeStyles} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";

export const DepaActionColStyles = makeStyles(theme => ({
    depaActionCol: {
        padding: 5,
        borderLeft: '#ededed',
        borderLeftWidth: 1,
        borderLeftStyle: "solid",
        textAlign: "center",
        width: 45,
        '&:hover': {
            color: 'white',
            backgroundColor: grey[300],
        },
        '&:last-child': {
            paddingRight: 5,
        },
    },
}));
