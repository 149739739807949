import React from "react";
import {required, TextInput, SimpleForm} from "react-admin";
import {Create} from "@react-admin/ra-enterprise";

const GroupCreate = (props) => (
    <Create {...props} title="Benutzergruppe erstellen" >
        <SimpleForm redirect={'list'}>
            <TextInput source="groupname" validate={required()} label={'Gruppenname'} fullWidth/>
        </SimpleForm>
    </Create>
);
export default GroupCreate;