import DepaDialogEditButton from "../../../DepaComponents/DepaDialogEditButton";
import {Datagrid, FileField, FileInput, ReferenceManyField, required, TextInput, UrlField,} from "react-admin";
import {DeleteRowButton,} from "@react-admin/ra-editable-datagrid";
import DepaDialogCreateButton from "../../../DepaComponents/DepaDialogCreateButton";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {Badge} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    field: {
        display: "none"
    },
}));

export const ContractPdfView = ({record: data}) => {
    const classes = useStyles()
    return (
        <DepaDialogEditButton label={''} fullWidth fullScreen={false}
                              icon={<Badge badgeContent={data.files.length} color="primary"
                                           variant="dot"><AttachFileIcon/> </Badge>}
                              title={'Dateien Bearbeiten'} tooltipTitle={'Datei bearbeiten'} saveButton={false}>
            <ReferenceManyField reference="service_contract_pdfs" target='serviceContract' addLabel={false}>
                <Datagrid
                    undoable
                >
                    <UrlField source='contentUrl' target={'_blank'} label={'Datei'} cellClassName={'w-auto'} fullWidth/>
                    <DeleteRowButton redirect={''} undoable={true}/>
                </Datagrid>
            </ReferenceManyField>
            <DepaDialogCreateButton disabled={data?.files.length !== 0} resource={'service_contract_pdfs'}>
                <FileInput source="file" validate={required()}
                           fullWidth>
                    <FileField source="src" title="title"/>
                </FileInput>
                <TextInput source={'serviceContract'} fullWidth
                           label={'Publikation'}
                           validate={required()}
                           defaultValue={data.id}
                           className={classes.field}
                />
            </DepaDialogCreateButton>
        </DepaDialogEditButton>
    )
}