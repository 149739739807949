import React from "react";
import {BooleanInput, FormTab, ReferenceInput, required, SelectInput, TabbedForm,} from "react-admin";
import {Create, DepaNumberInput, DepaTextInput} from "../../../DepaComponents/Components";
import {DateInput} from "../../../DepaComponents/DepaDatePicker/DepaDatePicker";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import {Divider, Grid} from "@material-ui/core";
import {FullNameField} from "./edit";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    statusField: {
        alignContent: 'center',
        height: '50%',
        justifyContent: 'center',
        paddingTop: 20,
    }
}));

const ContractCreate = (props) => {
    const classes = useStyles()
    return <Create {...props} title="Vertrag erstellen">
        <TabbedForm onSubmit={props.save} syncWithLocation={false} redirect={'list'}>
            <FormTab label={'Inhalt'} fullWidth>
                <Grid container spacing={2} xs={12} fullWidth>
                    <DepaTextInput source={'identifier'} xs={10} label={'resources.contracts.fields.identifier'}
                                   validate={required()}/>
                    <Grid item xs={2}>
                        <BooleanInput source="status" className={classes.statusField} defaultValue={true}
                                      label={'resources.contracts.fields.status'}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ReferenceInput source="crmContact" reference="crm_contacts"
                                        label={'resources.contracts.fields.crm_contact'}
                                        validate={required()} fullWidth
                                        perPage={100} sort={{field: 'surName', order: 'ASC'}}
                        >
                            <SelectInput optionText={<FullNameField/>} source={'surName'}/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ReferenceInput source="serviceContractType" reference="service_contract_types"
                                        label={'resources.contracts.fields.service_contract_type'} fullWidth
                                        validate={required()} sort={{field: 'identifier', order: 'ASC'}}
                        >
                            <SelectInput optionText="identifier"/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant={'fullWidth'}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DateInput source="dateStart" label={'resources.contracts.fields.date_start'} fullWidth
                                   options={{format: "d MMM yyyy", clearable: true}}
                                   providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DateInput source="dateEnd" label={'resources.contracts.fields.date_end'} fullWidth
                                   options={{format: "d MMM yyyy", clearable: true}}
                                   providerOptions={{utils: DateFnsUtils, locale: deLocale}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant={'fullWidth'}/>
                    </Grid>
                    <DepaNumberInput xs={12} md={6} source="hourPerMonth" min="0"
                                     label={'resources.contracts.fields.hoursPerMonth'}/>
                    <DepaNumberInput xs={12} md={6} source="monthMin" min="0"
                                     label={'resources.contracts.fields.monthMin'}/>
                    <DepaNumberInput xs={12} md={6} source="noticePeriod"
                                     label={'resources.contracts.fields.noticePeriod'}/>
                    <DepaTextInput xs={12} md={6} source="billingCycle"
                                   label={'resources.contracts.fields.billingCycle'}/>
                </Grid>
            </FormTab>
            <FormTab label={'resources.contracts.fields.comments'}>
                <DepaTextInput source="comments" label={'resources.contracts.fields.comments'} rows={3} multiline/>
            </FormTab>
        </TabbedForm>
    </Create>
};
export default ContractCreate;