import React from "react";
import {Datagrid, TextField, ShowButton, EditButton} from "react-admin";
import {DepaTooltip, List, DepaActionColStyles} from "../../../DepaComponents/Components";
import {Fade} from "@material-ui/core";

const TaskTemplateList = (props) => {
    const classes = DepaActionColStyles()
    return (<List {...props} title="resources.service_contract_rate.title">
            <Datagrid
                rowClick={'edit'}
                optimized
            >
                <TextField source="identifier" label="resources.service_contract_rate.fields.identifier"/>
                <TextField source="hourlyRate" label="resources.service_contract_rate.fields.hourly_rate"/>
                <DepaTooltip title="Anzeigen" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                             leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                    <ShowButton label={''}/>
                </DepaTooltip>
                <DepaTooltip title="Bearbeiten" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                             leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                    <EditButton label={''}/>
                </DepaTooltip>
            </Datagrid>
        </List>
    );
};

export default TaskTemplateList;