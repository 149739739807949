//import { default as CrmContactShow } from './show';
import { default as ServiceBillCreate } from './create';
import { default as ServiceBiltList } from './list';
import { default as ServiceBillEdit } from './edit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: ServiceBiltList,
    create: ServiceBillCreate,
    edit: ServiceBillEdit
    //show: CrmContactShow
};