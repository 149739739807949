import {useTranslate} from "react-admin";
import Typography from "@material-ui/core/Typography";
import React from "react";

export const SectionTitle = ({label}) => {
    const translate = useTranslate();
    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};