import React from "react";
import {Datagrid, TextField, useTranslate, ShowButton, EditButton} from "react-admin";
import {DepaTooltip, List, DepaActionColStyles} from "../../../DepaComponents/Components";
import {Fade, Card, CardContent} from "@material-ui/core";

const ContractTypePanel = ({ record}) => {
    const translate = useTranslate();
    return <Card title={'resources.contract_type.fields.description'}>
        <CardContent > {translate('resources.contract_type.fields.description')}: </CardContent>
        <CardContent dangerouslySetInnerHTML={{__html: record.description}}/>
    </Card>
};

const ContactList = (props) => {
    const classes = DepaActionColStyles()
    return (<List {...props} title="resources.contract_type.title">
            <Datagrid
                expand={<ContractTypePanel/>}
                rowClick={'edit'}
                optimized
            >
                <TextField source="identifier" label="resources.contract_type.fields.identifier"/>
                <DepaTooltip title="Anzeigen" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                             leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                    <ShowButton label={''}/>
                </DepaTooltip>
                <DepaTooltip title="Bearbeiten" aria-label="add" arrow TransitionComponent={Fade} enterDelay={500}
                             leaveDelay={0} cellClassName={classes.depaActionCol} enterNextDelay={500}>
                    <EditButton label={''}/>
                </DepaTooltip>
            </Datagrid>
        </List>
    );
};

export default ContactList;